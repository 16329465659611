/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ActorType {
    Admin = 'Admin',
    Dealer = 'Dealer',
    ServiceAccount = 'ServiceAccount',
    Provider = 'Provider',
    Customer = 'Customer',
}

export interface AddClaimFilesDto {
    newFiles: object[] | null;
}

export interface AddressClaimDto {
    street2?: string;
    city?: string;
    country?: string;
    googlePlaceId?: string;
    street?: string;
    streetNumber?: string;
    zipCode?: string;
}

export interface AddressDto {
    street2?: string;
    /** @example "Paris" */
    city?: string;
    /** @example "France" */
    country?: string;
    /** @example "ChIJIZX8lhRv5kcRGwYktiSb1Zw" */
    googlePlaceId?: string;
    /** @example "rue de la paix" */
    street?: string;
    /** @example "1" */
    streetNumber?: string;
    /** @example "75000" */
    zipCode?: string;
}

export interface AdminDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    /** @example "SuperAdmin" */
    role: AdminRole;
}

export interface AdminInvitationDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    id: string;
    /** @example "SuperAdmin" */
    role: AdminRole;
}

export enum AdminRole {
    SuperAdmin = 'SuperAdmin',
    ClaimsLeader = 'ClaimsLeader',
    ClaimsManager = 'ClaimsManager',
}

export interface AssignPaymentMeanToSubscriptionsDto {
    subscriptionIds: string[];
}

export interface AudiowizardConfigurationDto {
    laboratoryId: string;
    type: 'audiowizard';
}

export interface BeneficiaryDto {
    birthPlace?: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    birthdate?: string;
    /** @example "John" */
    firstName: string;
    id: string;
    /** @example "Doe" */
    lastName: string;
}

export enum BillingCyclePeriodEnum {
    OneShot = 'one_shot',
    Month = 'month',
}

export interface BrokerFeeRulesDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    operation: 'addition' | 'substraction' | 'percent' | 'multiply' | 'divide' | 'fixed';
    pricingRuleId: string;
}

export interface CSVDocumentDto {
    file: string[];
}

export interface CashPaymentContextDto {
    method: 'Cash';
}

export enum CertificateType {
    Default = 'Default',
    Vyv = 'Vyv',
    Assurlib = 'Assurlib',
    CetelemBP = 'CetelemBP',
    CetelemFP = 'CetelemFP',
    FloaAssuranceScolaire = 'FloaAssuranceScolaire',
}

export interface CheckoutMetadataDto {
    creditCardToken: CreditCardTokenDto;
    sourceId: string;
}

export interface CheckoutPaymentContextDto {
    data: CheckoutPaymentDataDto;
    method: 'CreditCard';
}

export interface CheckoutPaymentDataDto {
    cardToken: CreditCardTokenDto;
}

export interface ClaimAdminDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export interface ClaimAmendmentDto {
    admin: AdminDto;
    claimValuesAfter: ClaimValuesDto;
    claimValuesBefore: ClaimValuesDto;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    /** @example "StatusChanged" */
    event: ClaimEvent;
    id: string;
}

export interface ClaimAttachmentDto {
    file: ClaimFileDto;
    id: string;
}

export interface ClaimBeneficiaryDto {
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    firstName: string;
    id: string;
    lastName: string;
}

export interface ClaimClosureDto {
    closureReason: string;
}

export enum ClaimClosureReason {
    Paid = 'Paid',
    NoNewsFromClient = 'NoNewsFromClient',
    Fraud = 'Fraud',
    OutOfWarranty = 'OutOfWarranty',
}

export interface ClaimCompensationDto {
    amount: number;
    bic?: string;
    /** @format date-time */
    createdAt: string;
    iban?: string;
    id: string;
    /** @example "ToPay" */
    status: ClaimCompensationStatus;
}

export enum ClaimCompensationStatus {
    Incomplete = 'Incomplete',
    ToPay = 'ToPay',
    Paid = 'Paid',
}

export interface ClaimContractDto {
    commissionRate: number;
    id: string;
    product: ClaimProductDto;
    store: ClaimStoreDto;
}

export interface ClaimCountBySubscriptionDto {
    count: number;
}

export interface ClaimCountDto {
    claimsWithSubscription: number;
    claimsWithoutSubscription: number;
    myClaims: number;
}

export interface ClaimCustomerDto {
    address?: AddressClaimDto;
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    email: string;
    firstName: string;
    fullName?: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export interface ClaimDto {
    assigned?: ClaimAdminDto | null;
    /** @format date-time */
    associatedAt?: string;
    claimClosures?: ClaimClosureDto[];
    compensations?: ClaimCompensationDto[];
    /** @format date-time */
    declarationDate?: string;
    email?: string | null;
    /** @format date-time */
    eventDate?: string | null;
    externalFieldValues?: ExternalClaimFieldValueDto[];
    freshdeskId?: string;
    id: string;
    oldReference?: object | null;
    phoneNumber?: string | null;
    reason: string;
    reference: string;
    /** @example "New" */
    status: ClaimStatus;
    subscriptions: ClaimSubscriptionsDto[];
}

export enum ClaimEvent {
    StatusChanged = 'StatusChanged',
    CompensationAmountChanged = 'CompensationAmountChanged',
    PaymentStatusChanged = 'PaymentStatusChanged',
}

export interface ClaimExtractDto {
    count: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    file?: FileDto;
    id: string;
    name: string;
    total: number;
}

export interface ClaimFieldDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    description?: string;
    id: string;
    name: string;
    options?: string[];
    providerCode?: string;
    rank: number;
    required: boolean;
    title: string;
    type: FieldType;
}

export interface ClaimFileDto {
    /** @example "pdf" */
    extension: FilesExtensions;
    id: string;
    /** @example "application/pdf" */
    mimeType: FilesMimeTypes;
    name: string;
    path: string;
    /** @example "claim-documents" */
    type: FilesTypes;
}

export interface ClaimFilesDto {
    claim: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    file: ClaimFileDto;
    id: string;
}

export interface ClaimMessageDto {
    attachments: ClaimAttachmentDto[];
    bcc: string[];
    body: string;
    bodyText: string;
    cc: string[];
    claim: string;
    fromAdmin?: ClaimAdminDto | null;
    fromCustomer?: ClaimCustomerDto | null;
    fromEmail?: string | null;
    id: string;
    /** @format date-time */
    sentAt: string;
    toEmails: string[];
}

export interface ClaimNoteDto {
    attachments: ClaimAttachmentDto[];
    body: string;
    bodyText: string;
    claim: string;
    from: ClaimAdminDto;
    id: string;
    /** @format date-time */
    sentAt: string;
}

export interface ClaimProductDto {
    carenceDays: number;
    claimFields: FieldDto[];
    description?: string;
    id: string;
    name: string;
    protectionDays: number;
    services: ClaimServiceDto[];
    trialDays: number;
}

export interface ClaimProviderDto {
    id: string;
    name: string;
}

export interface ClaimServiceDto {
    id: string;
    provider: ClaimProviderDto;
    tax: number;
}

export enum ClaimStatus {
    New = 'New',
    Pending = 'Pending',
    ReadyForProcess = 'ReadyForProcess',
    ToPay = 'ToPay',
    Closed = 'Closed',
    AmendmentToPay = 'AmendmentToPay',
}

export interface ClaimStoreDto {
    id: string;
    name: string;
}

export interface ClaimSubscriptionsDto {
    amountInclTax: number;
    beneficiaries: ClaimBeneficiaryDto[];
    contract: ClaimContractDto;
    customers: ClaimCustomerDto[];
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    endCoverDate: string;
    id: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    insuranceDate: string;
    isInTrial: boolean;
    reference: string;
    serviceFieldValues?: FieldValueDto[];
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    startCoverDate: string;
    /** @example "active" */
    status: SubscriptionStatus;
}

export interface ClaimValuesDto {
    amount?: number | null;
    claimClosures?: ClaimClosureDto[];
    /** @format date-time */
    declarationDate?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    reason?: string;
    reference?: string;
    /** @example "New" */
    status?: ClaimStatus;
}

export interface CompensationListItemDto {
    amount: number;
    claimId: string;
    claimReference: string;
    id: string;
    /** @example "ToPay" */
    status: ClaimCompensationStatus;
    subscriptions: CompensationListItemSubscriptionDto[];
}

export interface CompensationListItemSubscriptionDto {
    customerFullname?: string;
    id: string;
    productName: string;
    providerName: string;
    storeId: string;
}

export interface ComputedFieldDto {
    id: string;
    name: string;
    operator: ComputedFieldOperatorEnum;
    serviceFieldIdLeftOperand: string;
    serviceFieldIdRightOperand: string;
}

export enum ComputedFieldOperatorEnum {
    DateDiff = 'date_diff',
}

export interface ContractChangeResultResponseDto {
    failed: FailedDto[];
    success: number;
}

export interface ContractDocumentTemplateDto {
    documentTemplate: DocumentTemplateDto;
    id: string;
    templateType: string;
}

export interface ContractDto {
    brokerFeeRules: BrokerFeeRulesDto[];
    commissionRate: number;
    contractDocumentTemplates: ContractDocumentTemplateDto[];
    contractMailTemplates: ContractMailTemplateDto[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    docusignId: string | null;
    /** @format date-time */
    endAt: string;
    files: FileDto[] | null;
    id: string;
    managementFees: number;
    product: ContractProductDto;
    showPriceOnCertificate: boolean;
    /** @format date-time */
    startAt: string;
    status: ContractStatus;
    store: ContractStoreDto;
}

export interface ContractFilesDto {
    contract: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    documentType: 'document' | 'fic-document' | 'notice-document' | 'ipid-document';
    file: string;
    id: string;
}

export interface ContractMailTemplateDto {
    cci: string[];
    contract: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    mailTemplate: MailTemplateDto;
    templateType:
        | 'register'
        | 'subscription_created'
        | 'invitation'
        | 'invitation_registered'
        | 'forgotten_password'
        | 'magic_link'
        | 'code_verification'
        | 'store_invoice'
        | 'customer_warning'
        | 'document_subscription'
        | 'retract_period'
        | 'importNotification'
        | 'compensationSatisfactionSurvey'
        | 'tacit_renewal_reminder'
        | 'tacit_renewal_reminder_with_change_payment_mean';
}

export interface ContractProductDto {
    backdatingDays: number;
    beneficiariesNumber: number;
    carenceDays: number;
    certificateType: CertificateType;
    description: string | null;
    files: FileDto[];
    hasExternalId: boolean;
    id: string;
    name: string;
    ocrUri: string;
    pricings: PricingDto[];
    protectionDays: number;
    retractPeriod: number;
    services: ContractProductServiceDto[];
    tacitRenewalCycleDays: number;
    tacitRenewalCycles: number;
    trialDays: number;
}

export interface ContractProductServiceDto {
    billingCyclePeriod: string;
    fields: FieldDto[];
    id: string;
    name: string;
    serviceFields: FieldDto[];
}

export enum ContractStatus {
    Active = 'Active',
    AwaitingSignature = 'AwaitingSignature',
    Cancelled = 'Cancelled',
}

export interface ContractStoreDto {
    id: string;
    name: string;
}

export interface ContractSubscriptionDto {
    amountInclTax: number;
    contract: ContractSubscriptionsContractDto;
    /** @format date-time */
    createdAt: string;
    customerAllowsRenewal: boolean;
    customers: CustomerDto[];
    dealer: DealerDto;
    /** @format date-time */
    deletedAt: string;
    /** @format date-time */
    endCoverDate: string;
    id: string;
    /** @format date-time */
    insuranceDate: string;
    isInTrial: boolean;
    reference: string;
    salesChannel: SalesChannelsDto;
    /** @format date-time */
    startCoverDate: string;
    status: SubscriptionStatus;
}

export interface ContractSubscriptionsContractDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    product: ContractSubscriptionsProductDto;
    store: ContractSubscriptionsStoreDto;
}

export interface ContractSubscriptionsProductDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    name: string;
}

export interface ContractSubscriptionsStoreDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
}

export interface ContractWithServiceFieldsDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    product: ProductDto;
    status: ContractStatus;
}

export interface CoverDateRuleDto {
    operation: CoverDateRuleOperation;
    operationServiceFieldId: string;
    resultValue: number;
}

export enum CoverDateRuleOperation {
    Addition = 'addition',
    Substraction = 'substraction',
}

export interface CreateAdminDto {
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
    role: string;
}

export interface CreateAdminInvitationDto {
    email: string;
    /** @example "SuperAdmin" */
    role: AdminRole;
}

export interface CreateBeneficiaryDto {
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    firstName: string;
    lastName: string;
}

export interface CreateBrokerFeeRulesDto {
    amount: number;
    operation: 'addition' | 'substraction' | 'percent' | 'multiply' | 'divide' | 'fixed';
    pricingRuleId: string;
}

export interface CreateCompensationDto {
    amount: number;
    bic?: string;
    claimId: string;
    iban?: string;
}

export interface CreateContractDto {
    brokerFeeRules: CreateBrokerFeeRulesDto[];
    commissionRate: number;
    files: FileDto[] | null;
    managementFees: number;
    newFiles: object[] | null;
    newFilesExtraData: FileExtraDataDto[] | null;
    productId: string;
    showPriceOnCertificate: boolean;
    signatoryId: string;
    /** @format date-time */
    startAt: string;
    status: string;
}

export interface CreateCoverDateRuleDto {
    operation: CoverDateRuleOperation;
    operationServiceFieldId: string;
    resultValue: number;
}

export interface CreateCustomerAuthTokenDto {
    code: string;
    redirectUri: string;
}

export interface CreateCustomerDto {
    address: AddressDto;
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    customData?: CustomCustomerDataDto;
    email: string;
    /** @pattern /[*@:=[\]?|\\"<>+;]/ */
    firstName: string;
    flags?: CreateCustomerFlagsDto;
    /** @pattern /[*@:=[\]?|\\"<>+;]/ */
    lastName: string;
    phoneNumber: string;
}

export interface CreateCustomerFlagsDto {
    isOptinEmail?: boolean;
    isOptinPhone?: boolean;
}

export interface CreateDataProviderDto {
    connector: DataProviderConnector;
    dataProviderFields?: CreateDataProviderFieldDto[];
    name: string;
}

export interface CreateDataProviderFieldDto {
    type: DataProviderFieldType;
    value: string;
}

export interface CreateDealerDto {
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
}

export interface CreateDealerStoreDto {
    dealer: CreateDealerDto;
    /** @example "User" */
    role: DealerRole;
}

export interface CreateDocumentTemplateDto {
    content: GenericElementDto[];
    name: string;
}

export interface CreateEmailVerificationCodeDto {
    email: string;
}

export interface CreateFieldValueDto {
    name: string;
    value?: string | boolean | number | null;
}

export interface CreateInvitationDto {
    email: string;
    /** @example "User" */
    role: DealerRole;
}

export interface CreateMagicLinkDto {
    email: string;
}

export interface CreateMailTemplateDto {
    name: string;
    templateId: string;
    /** @example "magic_link" */
    type: MailTemplateType;
}

export interface CreateMultiCustomersMagicLinkDto {
    email: string;
    websiteId?: string;
}

export interface CreateOrUpdateClaimFieldDto {
    description?: string;
    id?: string;
    name: string;
    options?: string[];
    providerCode?: string;
    rank: number;
    required: boolean;
    title: string;
    type: FieldType;
}

export interface CreateOrUpdateComputedFieldDto {
    id?: string;
    name: string;
    operator: ComputedFieldOperatorEnum;
    serviceFieldIdLeftOperand: string;
    serviceFieldIdRightOperand: string;
}

export interface CreateOrUpdateFieldDto {
    description?: string;
    id?: string;
    name: string;
    options?: string[];
    rank: number;
    required: boolean;
    title: string;
    type: FieldType;
}

export interface CreateOrUpdatePricingDto {
    amount: number;
    computedFields: CreateOrUpdateComputedFieldDto[];
    id?: string;
    pricingRules: CreateOrUpdatePricingRuleDto[];
}

export interface CreateOrUpdatePricingRuleDto {
    id?: string;
    rank: number;
    rule: RuleDto;
}

export interface CreatePaymentMeanDto {
    paymentContext:
        | CheckoutPaymentContextDto
        | StripePaymentContextDto
        | SepaPaymentContextDto
        | CustomPaymentContextDto;
    redirectUrls: RedirectUrlsDto;
    subscriptionIds?: string[];
}

export interface CreatePaymentMethodDto {
    disabled3DS: boolean;
    fields?: CreateOrUpdateFieldDto[];
    name: string;
    type: PaymentMethodType;
}

export interface CreateProductDto {
    beneficiariesNumber: number;
    carenceDays: number;
    certificateType: CertificateType;
    compensationArea?: string | null;
    coverArea?: string | null;
    coveredCategories?: string[];
    description?: string | null;
    exclusionArea?: string | null;
    files?: FileDto[];
    hasExternalId: boolean;
    maxCoveredPrice?: number;
    multiplyByBeneficiaries: boolean;
    name: string;
    ocrUri?: string;
    protectionDays: number;
    retractPeriod: number;
    security: string | null;
    services: string[];
    status: ProductStatus;
    tacitRenewalCycleDays: number;
    tacitRenewalCycles: number;
    tags: TagDto[];
    technicalName?: string | null;
    trialDays: number;
    uncoveredArea?: string | null;
    voidFormUri?: string;
}

export interface CreateProviderAccountDto {
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
}

export interface CreateProviderDto {
    address: AddressDto;
    description: string | null;
    legalTerms: LegalTermsDto | null;
    name: string;
    renunciationRights: string;
}

export interface CreateSalesChannelsDto {
    /** @default false */
    askEmailVerificationCode?: boolean;
    contracts: string[];
    dataProvider?: string;
    dataProviderConfiguration?:
        | SetAudiowizardConfigurationDto
        | SetEseasonConfigurationDto
        | SetInaxelConfigurationDto
        | SetMaevaConfigurationDto;
    disabled: boolean;
    /** @default false */
    isCertificateGenerationDisabled?: boolean;
    isDisplayedInApp: boolean;
    /** @default false */
    isSendEmailEnabled?: boolean;
    name: string;
    paymentMethods: string[];
    rank: string;
    /** @default false */
    requestDocumentsSignature: boolean;
    type: 'WebStore' | 'PointOfSales';
    /** @default false */
    verifyPrice?: boolean;
}

export interface CreateServiceDto {
    billingCycleCount: number;
    billingCyclePeriod: 'one_shot' | 'month';
    claimText?: string | null;
    fields?: CreateOrUpdateFieldDto[];
    legalMentions?: string | null;
    name: string;
    priceFixAmount: number;
    pricePercent: number;
    provider: string;
    tax: number;
}

export interface CreateServiceFieldCheckboxValueDto {
    id: string;
    type: 'checkbox';
    value: boolean;
}

export interface CreateServiceFieldDateValueDto {
    id: string;
    type: 'datetime';
    /** @format date-time */
    value: string;
}

export interface CreateServiceFieldDropDownValueDto {
    id: string;
    type: 'dropdown';
    value: string;
}

export interface CreateServiceFieldNumberValueDto {
    id: string;
    type: 'number';
    value: number;
}

export interface CreateServiceFieldStringValueDto {
    id: string;
    type: 'string';
    value: string;
}

export interface CreateServiceFieldValueDto {
    id: string;
    value: string | boolean | number;
}

export interface CreateStoreBillingInformationsDto {
    autoGeneration: boolean;
    autoMail: boolean;
    autoOptimisation: boolean;
    bic: string | null;
    billingEmail: string[];
    billingIban: string | null;
    overrideCompanyName?: string;
}

export interface CreateStoreDto {
    address: AddressDto;
    legalName: string;
    legalTerms: LegalTermsDto | null;
    name: string;
    sendImportNotification: boolean;
    settings: SettingsDto | null;
    validationStatus: 'Pending' | 'Validated' | 'Rejected';
    websiteUrl: string;
}

export interface CreateStoreInvoicesDto {
    storeIds?: string[];
}

export interface CreateSubscriptionDto {
    beneficiaries?: CreateBeneficiaryDto[];
    customers: CreateCustomerDto[];
    externalId?: string;
    /** @format date-time */
    insuranceDate?: string;
    paymentContext:
        | SubscriptionCashPaymentContextDto
        | SubscriptionCheckoutPaymentContextDto
        | SubscriptionStripePaymentContextDto
        | SubscriptionSepaPaymentContextDto
        | SubscriptionCustomPaymentContextDto;
    redirectUrls: RedirectUrlsDto;
    salesChannelId: string;
    serviceFieldValues: CreateFieldValueDto[];
    totalAmount: number;
    utmData?: CreateSubscriptionUtmDataDto;
    verificationCode?: string;
}

export interface CreateSubscriptionResponseDto {
    id: string;
    redirectUrl: string;
    reference: string;
    /** @example "active" */
    status: SubscriptionStatus;
}

export interface CreateSubscriptionUtmDataDto {
    campaign?: string;
    content?: string;
    medium?: string;
    source?: string;
    term?: string;
}

export interface CreateSubscriptionV1Dto {
    beneficiaries?: CreateBeneficiaryDto[];
    customers: CreateCustomerDto[];
    externalId?: string;
    /** @format date-time */
    insuranceDate?: string;
    paymentContext:
        | SubscriptionCashPaymentContextDto
        | SubscriptionCheckoutPaymentContextDto
        | SubscriptionStripePaymentContextDto
        | SubscriptionSepaPaymentContextDto;
    redirectUrls: RedirectUrlsDto;
    salesChannelId: string;
    serviceFieldValues: (
        | CreateServiceFieldNumberValueDto
        | CreateServiceFieldCheckboxValueDto
        | CreateServiceFieldStringValueDto
        | CreateServiceFieldDateValueDto
        | CreateServiceFieldDropDownValueDto
    )[];
    totalAmount: number;
    verificationCode?: string;
}

export interface CreateTicketacSubscriptionsDto {
    beneficiaries: TicketacBeneficiaryDto[];
    items: ItemDto[];
    payment_method: string;
    product_reference: string;
}

export interface CreateWebhookDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    /** @example "subscription:created" */
    events: WebhookEvent[];
    id: string;
    isActive: boolean;
    sharedKey: string;
    url: string;
}

export interface CreateWebsiteDto {
    magicLinkMailTemplateId?: string;
    magicLinkRedirectUrl?: string;
    name: string;
    /** @example "salesRoute" */
    type: WebsiteType;
    url: string;
}

export interface CreationResponses {
    'adresse du souscripteur': string;
    'code postal du souscripteur': string;
    'complement adresse du souscripteur': string;
    'date de fin de contrat': string;
    'date de naissance du souscripteur': string;
    'date de souscription': string;
    'email du souscripteur': string;
    'id du canal de vente': string;
    'id du contrat': string;
    'id produit': string;
    'nom du souscripteur': string;
    'numero de dossier': string;
    'numero de rue du souscripteur': string;
    'numero de telephone du souscripteur': string;
    'pays du souscripteur': string;
    'prenom du souscripteur': string;
    'ville du souscripteur': string;
    errors?: ErrorsDataImportDto[];
    idSubscription: string;
    status: 'CREATED' | 'DUPLICATE' | 'ERROR';
}

export interface CreditCardTokenDto {
    last4: string;
    bin: string;
    card_category?: 'Consumer' | 'Commercial';
    card_type?: 'Credit' | 'Debit' | 'Prepaid' | 'Charge';
    expires_on: string;
    expiry_month: number;
    expiry_year: number;
    issuer_country?: string;
    preferred_scheme?: 'mastercard' | 'visa' | 'cartes_bancaires';
    product_id?: string;
    product_type?: string;
    scheme?:
        | 'Visa'
        | 'Mastercard'
        | 'AMERICAN EXPRESS'
        | 'Diners Club International'
        | 'Maestro'
        | 'Discover';
    token: string;
    type: string;
}

export enum CurrencyCode {
    USD = 'USD',
    CAD = 'CAD',
    EUR = 'EUR',
    AED = 'AED',
    AFN = 'AFN',
    ALL = 'ALL',
    AMD = 'AMD',
    ARS = 'ARS',
    AUD = 'AUD',
    AZN = 'AZN',
    BAM = 'BAM',
    BDT = 'BDT',
    BGN = 'BGN',
    BHD = 'BHD',
    BIF = 'BIF',
    BND = 'BND',
    BOB = 'BOB',
    BRL = 'BRL',
    BWP = 'BWP',
    BYN = 'BYN',
    BZD = 'BZD',
    CDF = 'CDF',
    CHF = 'CHF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    CVE = 'CVE',
    CZK = 'CZK',
    DJF = 'DJF',
    DKK = 'DKK',
    DOP = 'DOP',
    DZD = 'DZD',
    EEK = 'EEK',
    EGP = 'EGP',
    ERN = 'ERN',
    ETB = 'ETB',
    GBP = 'GBP',
    GEL = 'GEL',
    GHS = 'GHS',
    GNF = 'GNF',
    GTQ = 'GTQ',
    HKD = 'HKD',
    HNL = 'HNL',
    HRK = 'HRK',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    IQD = 'IQD',
    IRR = 'IRR',
    ISK = 'ISK',
    JMD = 'JMD',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KHR = 'KHR',
    KMF = 'KMF',
    KRW = 'KRW',
    KWD = 'KWD',
    KZT = 'KZT',
    LBP = 'LBP',
    LKR = 'LKR',
    LTL = 'LTL',
    LVL = 'LVL',
    LYD = 'LYD',
    MAD = 'MAD',
    MDL = 'MDL',
    MGA = 'MGA',
    MKD = 'MKD',
    MMK = 'MMK',
    MOP = 'MOP',
    MUR = 'MUR',
    MXN = 'MXN',
    MYR = 'MYR',
    MZN = 'MZN',
    NAD = 'NAD',
    NGN = 'NGN',
    NIO = 'NIO',
    NOK = 'NOK',
    NPR = 'NPR',
    NZD = 'NZD',
    OMR = 'OMR',
    PAB = 'PAB',
    PEN = 'PEN',
    PHP = 'PHP',
    PKR = 'PKR',
    PLN = 'PLN',
    PYG = 'PYG',
    QAR = 'QAR',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    RWF = 'RWF',
    SAR = 'SAR',
    SDG = 'SDG',
    SEK = 'SEK',
    SGD = 'SGD',
    SOS = 'SOS',
    SYP = 'SYP',
    THB = 'THB',
    TND = 'TND',
    TOP = 'TOP',
    TRY = 'TRY',
    TTD = 'TTD',
    TWD = 'TWD',
    TZS = 'TZS',
    UAH = 'UAH',
    UGX = 'UGX',
    UYU = 'UYU',
    UZS = 'UZS',
    VEF = 'VEF',
    VND = 'VND',
    XAF = 'XAF',
    XOF = 'XOF',
    YER = 'YER',
    ZAR = 'ZAR',
    ZMK = 'ZMK',
    ZWL = 'ZWL',
}

export interface CustomCustomerDataDto {
    birthName?: string;
    civility?: string;
    externalId?: string;
    familySituation?: string;
    nationality?: string;
    professionalSituation?: string;
}

export interface CustomPaymentContextDto {
    method: 'Custom';
    paymentMethodFieldValues: string[];
}

export interface CustomerAuthToken {
    refreshToken: string;
    token: string;
}

export interface CustomerDto {
    address?: AddressDto;
    birthPlace?: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    birthdate?: string;
    customData?: CustomCustomerDataDto;
    /** @example "example@email.com" */
    email: string;
    /** @example "John" */
    firstName: string;
    flags?: CustomerFlagsDto;
    /** @example "John Doe" */
    fullName?: string;
    id: string;
    /** @example "Doe" */
    lastName: string;
    /** @example "0600000000" */
    phoneNumber: string;
    risk?: CustomerRisk;
    riskMatchingScore?: number;
}

export interface CustomerFlagsDto {
    isOptinEmail: boolean;
    isOptinPhone: boolean;
}

export interface CustomerPaginatedSubscriptionContractDto {
    id: string;
    product: CustomerPaginatedSubscriptionContractProductDto;
}

export interface CustomerPaginatedSubscriptionContractProductDto {
    id: string;
    name: string;
}

export interface CustomerPaginatedSubscriptionDto {
    amountInclTax: number;
    contract: CustomerPaginatedSubscriptionContractDto;
    customerAllowsRenewal: boolean;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    endCoverDate: string;
    id: string;
    isInTrial: boolean;
    paymentMeanId?: string;
    reference: string;
    serviceFieldValues?: FieldValueDto[];
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    startCoverDate: string;
    status: SubscriptionStatus;
    storeId: string;
}

export interface CustomerPaginatedTransactionDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    currency: CurrencyCode;
    /** @format date-time */
    deletedAt: string;
    id: string;
    productName: string;
    /** @example "success" */
    status: TransactionStatus;
    subscriptionId: string;
}

export interface CustomerPaymentMeanDto {
    id: string;
    metadata?: MollieMetadataDto | CheckoutMetadataDto | StripeMetadataDto;
    paymentMethod?: CustomerPaymentMeanPaymentMethodDto;
    paymentMethodFieldValues?: FieldValueDto[];
    procedures: PaymentMeanProcedureDto[];
    status: PaymentMeanStatus;
    subscriptions: CustomerPaymentMeanSubscriptionDto[];
    /** @example "checkout_credit_card" */
    type: PaymentMeanType;
}

export interface CustomerPaymentMeanPaymentMethodDto {
    fields: FieldDto[];
    name: string;
    type: PaymentMethodType;
}

export interface CustomerPaymentMeanSubscriptionDto {
    id: string;
    productName: string;
    status: SubscriptionStatus;
}

export enum CustomerRisk {
    UNKNOWN = 'UNKNOWN',
    NO_RISK_FOUND = 'NO_RISK_FOUND',
    POTENTIAL_RISK = 'POTENTIAL_RISK',
    MATCH = 'MATCH',
    STRUCK_OFF = 'STRUCK_OFF',
}

export interface CustomerStoreCustomerDto {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export interface CustomerStoreDto {
    customer: CustomerStoreCustomerDto;
    store: CustomerStoreStoreDto;
}

export interface CustomerStoreStoreDto {
    id: string;
    logoUrl?: string;
    name: string;
}

export interface CustomerSubscriptionsLastTransactionDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    currency: CurrencyCode;
    /** @format date-time */
    deletedAt: string;
    id: string;
    productName: string;
    /** @example "success" */
    status: TransactionStatus;
    subscriptionId: string;
}

export interface DataErrorHeaderDto {
    expectedValues: string[];
    missingValues: string[];
    property: string;
    value: string[];
}

export interface DataExportColumnDto {
    name?: string;
    path: string;
}

export interface DataExportParamsDto {
    columns: DataExportColumnDto[];
}

export enum DataProviderConnector {
    Soap = 'soap',
    Ftp = 'ftp',
    Api = 'api',
    Googlesheet = 'googlesheet',
}

export interface DataProviderDto {
    connector: DataProviderConnector;
    /** @format date-time */
    createdAt: string;
    dataProviderFields?: DataProviderFieldDto[];
    /** @format date-time */
    deletedAt: string;
    id: string;
    name: string;
}

export interface DataProviderFieldDto {
    /** @format date-time */
    createdAt: string;
    dataProvider: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    type: DataProviderFieldType;
    value: string;
}

export enum DataProviderFieldType {
    Password = 'password',
    Username = 'username',
    IpAddress = 'ip_address',
    Folder = 'folder',
    Url = 'url',
    Ssl = 'ssl',
    ApiEndpoint = 'api_endpoint',
    ApiResponseType = 'api_response_type',
    ApiWithCredentials = 'api_with_credentials',
    GoogleAccountEmail = 'google_account_email',
    GooglePrivateKey = 'google_private_key',
}

export interface DealerDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export enum DealerRole {
    Admin = 'Admin',
    User = 'User',
    Seller = 'Seller',
}

export interface DealerStoreDto {
    dealer: DealerDto;
    dealerRole: string;
}

export interface DealerWithRoleDto {
    /** @example "User" */
    dealerRole: DealerRole;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    locked: boolean;
    phoneNumber: string;
}

export interface DiffsExtraValuesDto {
    name?: string;
    reference?: string;
}

export interface DiffsValuesDto {
    amount?: number;
    status?: string;
}

export interface DocumentTemplateDto {
    content: GenericElementDto[];
    id: string;
    name: string;
}

export interface DuplicateStoreConfigDto {
    destStoreId: string;
}

export interface EmailVerificationCodeDto {
    email: string;
    id: string;
}

export interface EmbeddableLinksDto {
    yousignProcedureUri: string;
}

export interface EmbeddableMollieMandateDto {
    createdAt: string;
    details: object;
    id: string;
    mandateReference: string;
    method: 'directdebit' | 'creditcard' | 'paypal';
    mode: 'test' | 'live';
    signatureDate: string;
    status: 'valid' | 'invalid' | 'pending';
}

export interface ErrorHeaderDto {
    data: DataErrorHeaderDto[];
    error: string;
    message: MessageError[];
    statusCode: number;
}

export interface ErrorTsDto {
    message: string;
    name: string;
    stack: string;
}

export interface ErrorsDataImportDto {
    exceptions: ExceptionImportDto[];
    property: string;
    value: string;
}

export interface ErrorsDto {
    error: string;
    message: MessageError[];
    statusCode: number;
}

export interface EseasonConfigurationDto {
    campingId: string;
    type: 'eseason';
}

export enum EventCode {
    CLAIM_STATUS_CHANGE = 'CLAIM_STATUS_CHANGE',
    CLAIM_COMPENSATION_AMOUNT_CHANGE = 'CLAIM_COMPENSATION_AMOUNT_CHANGE',
    CLAIM_COMPENSATION_EXPORT = 'CLAIM_COMPENSATION_EXPORT',
}

export interface ExceptionImportDto {
    expectedValues: string[];
    type: string;
}

export interface ExportConfigurationQueryDto {
    products: ProductConfigExportDetails;
    services: ServiceConfigExportDetails;
    stores: StoreConfigExportDetails;
}

export interface ExternalClaimFieldValueDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    description?: string | null;
    freshdeskId?: number;
    id: string;
    name: string;
    options?: string[] | null;
    /** @example "checkbox" */
    type: FieldType;
    value: string | boolean | number | null;
}

export interface FailedDto {
    reason: string;
    subscriptionId: string;
}

export interface FetchPaymentMeanProcedureUpdateResponseDto {
    procedure: PaymentMeanProcedureDto;
    redirectUrl: string;
}

export interface FetchSubscriptionProcedureUpdateResponseDto {
    procedure: SubscriptionProcedureDto;
    redirectUrl: string;
}

export interface FieldDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    description?: string;
    id: string;
    name: string;
    options?: string[];
    rank: number;
    required: boolean;
    title: string;
    type: FieldType;
}

export enum FieldType {
    Checkbox = 'checkbox',
    String = 'string',
    Number = 'number',
    Dropdown = 'dropdown',
    Datetime = 'datetime',
}

export interface FieldValueDto {
    description?: string;
    field: FieldDto;
    id: string;
    name: string;
    options?: string[];
    type: FieldType;
    value: string | boolean | number;
}

export interface FileDto {
    contractFiles: ContractFilesDto[];
    /** @example "pdf" */
    extension: FilesExtensions;
    id: string;
    /** @example "application/pdf" */
    mimeType: FilesMimeTypes;
    name: string;
    path: string;
    productFiles: ProductFilesDto[];
    /** @example "claim-documents" */
    type: FilesTypes;
}

export interface FileExtraDataDto {
    classification: string;
    name: string;
}

export enum FilesExtensions {
    Pdf = 'pdf',
    Jpg = 'jpg',
    Jpeg = 'jpeg',
    Png = 'png',
    Xml = 'xml',
    Csv = 'csv',
    Json = 'json',
    Xlsx = 'xlsx',
    Docx = 'docx',
    Cfb = 'cfb',
    Heic = 'heic',
    Zip = 'zip',
    Value7Z = '7z',
    Odt = 'odt',
    Unknown = 'unknown',
    Jxr = 'jxr',
    Bmp = 'bmp',
    Tif = 'tif',
    Tiff = 'tiff',
    Svg = 'svg',
}

export enum FilesMimeTypes {
    ApplicationXml = 'application/xml',
    ApplicationJson = 'application/json',
    Unknown = 'unknown',
    ApplicationZip = 'application/zip',
    ApplicationXCfb = 'application/x-cfb',
    ApplicationX7ZCompressed = 'application/x-7z-compressed',
    ApplicationVndOasisOpendocumentText = 'application/vnd.oasis.opendocument.text',
    ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ApplicationPdf = 'application/pdf',
    ImageJpeg = 'image/jpeg',
    ImagePng = 'image/png',
    TextCsv = 'text/csv',
    ImageHeic = 'image/heic',
    ImageVndMsPhoto = 'image/vnd.ms-photo',
    ImageTiff = 'image/tiff',
    ImageBmp = 'image/bmp',
    ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ImageSvgXml = 'image/svg+xml',
}

export enum FilesTypes {
    ContractDocuments = 'contract-documents',
    ProductDocuments = 'product-documents',
    ClaimDocuments = 'claim-documents',
    ClaimMessageAttachments = 'claim-message-attachments',
    ClaimNoteAttachments = 'claim-note-attachments',
    SepaMandates = 'sepa-mandates',
    SubscriptionCertificates = 'subscription-certificates',
    SubscriptionBillingTimeline = 'subscription-billing-timeline',
    ImportSubscriptionsFile = 'import-subscriptions-file',
    ImportedInvoices = 'imported-invoices',
    ExtractedClaimsToPay = 'extracted-claims-to-pay',
    ImportLogs = 'import-logs',
}

export interface ForgottenPasswordDto {
    email: string;
}

export interface FreshdeskTicketDto {
    ticket_id: string;
}

export interface FreshdeskTicketNotificationDto {
    freshdesk_webhook: FreshdeskTicketDto;
}

export interface GenericElementDto {
    children: (GenericElementDto | TextElementDto)[];
    type: string;
}

export interface GetAuditLogsResponseDto {
    auditLogDiff?: GetDiffsDto;
    /** @format date-time */
    createdAt: string;
    entityId?: string;
    entityName: string;
    id: string;
    message: string;
    user?: AdminDto;
    userEmail?: string;
}

export interface GetClaimsByDate {
    count: number;
    date: number;
}

export interface GetClaimsByStatus {
    count: number;
    /** @example "Pending" */
    status: ClaimStatus;
}

export interface GetDiffsDto {
    afterData?: DiffsValuesDto;
    beforeData?: DiffsValuesDto;
    eventCode?: EventCode;
    extraData?: DiffsExtraValuesDto;
}

export interface GetPriceBySubscriptionIdDto {
    quantity: number;
    serviceFieldValues: UpdateFieldValueDto[];
}

export interface GetStorePremiumByDayDto {
    day: string;
    sum: number;
}

export interface GetStoreProductsSalesDto {
    product: GetStoreProductsSalesProductDto;
    sum: number;
}

export interface GetStoreProductsSalesProductDto {
    id: string;
    name: string;
}

export interface GetSubscriptionPriceDto {
    quantity: number;
    serviceFieldValues: CreateFieldValueDto[];
}

export interface GetSubscriptionPriceDtoV1 {
    quantity: number;
    serviceFieldValues: (
        | CreateServiceFieldNumberValueDto
        | CreateServiceFieldCheckboxValueDto
        | CreateServiceFieldStringValueDto
        | CreateServiceFieldDateValueDto
        | CreateServiceFieldDropDownValueDto
    )[];
}

export interface GetSubscriptionsStatsByDateRange {
    id: string;
    label: string;
    value: number;
}

export interface ImportClaimFieldsDto {
    claimFields: CreateOrUpdateClaimFieldDto[];
    claimMapping: RequestUpdateClaimPropertyMappingsDto;
    productsIds: string[];
}

export interface ImportConfigDto {
    contracts: string[];
    files: object[];
    products: string[];
    providers: string[];
    services: string[];
    stores: string[];
}

export interface ImportCsvResponseDto {
    rows: CreationResponses[];
    rowsExisting: number;
    rowsImported: number;
    rowsIncorrect: number;
    rowsReceived: number;
}

export interface ImportJobContractDto {
    id: string;
    product: ImportJobProductDto;
    store: ImportJobStoreDto;
}

export interface ImportJobDataByStoreDto {
    contract: ImportJobContractDto | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    details: ImportCsvResponseDto;
    /** @format date-time */
    endedAt?: string;
    id: string;
    /** @format date-time */
    startedAt: string;
    status: string;
    type: ImportJobType;
}

export interface ImportJobProductDto {
    id: string;
    name: string;
}

export interface ImportJobStoreDto {
    id: string;
    name: string;
}

export enum ImportJobType {
    Ecox = 'ecox',
    Maeva = 'maeva',
    Eseason = 'eseason',
    Inaxel = 'inaxel',
    Resalys = 'resalys',
    Audiowizard = 'audiowizard',
    Csv = 'csv',
}

export interface InaxelConfigurationDto {
    password: string;
    serverIp: string;
    type: 'inaxel';
    userName: string;
}

export interface InvitationDto {
    acceptedByAdmin?: AdminDto;
    acceptedByDealer?: DealerDto;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    id: string;
    /** @example "User" */
    role: DealerRole;
    store: InvitationStore;
}

export interface InvitationStore {
    id: string;
    name: string;
}

export interface ItemDto {
    date_end: string;
    date_start: string;
    event_address: string;
    event_city: string;
    event_country: string;
    event_name: string;
    event_type: string;
    event_zipcode: string;
    label: string;
    ticket_pricing_ht: number;
    ticket_pricing_ttc: number;
}

export interface LegalTermsDto {
    capital: string;
    naf: string;
    orias: string;
    rcs: string;
    siret: string;
}

export interface LoginResponseDto {
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" */
    token: string;
    user: LoginUserResponseDto;
}

export interface LoginUserRequestDto {
    /** @example "monEmail@test.fr" */
    email: string;
    /** @example "password" */
    password: string;
}

export interface LoginUserResponseDto {
    /** @example "Dealer" */
    actorType: ActorType;
    id: string;
}

export interface MaevaConfigurationDto {
    type: 'maeva';
}

export interface MailTemplateDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    name: string;
    templateId: string;
    /** @example "magic_link" */
    type?: MailTemplateType;
}

export enum MailTemplateType {
    Register = 'register',
    SubscriptionCreated = 'subscription_created',
    Invitation = 'invitation',
    InvitationRegistered = 'invitation_registered',
    ForgottenPassword = 'forgotten_password',
    MagicLink = 'magic_link',
    CodeVerification = 'code_verification',
    StoreInvoice = 'store_invoice',
    CustomerWarning = 'customer_warning',
    DocumentSubscription = 'document_subscription',
    RetractPeriod = 'retract_period',
    ImportNotification = 'importNotification',
    CompensationSatisfactionSurvey = 'compensationSatisfactionSurvey',
    TacitRenewalReminder = 'tacit_renewal_reminder',
    TacitRenewalReminderWithChangePaymentMean = 'tacit_renewal_reminder_with_change_payment_mean',
}

export interface MessageError {
    errors: string[];
    field: string;
}

export interface MollieMetadataDto {
    iban: string;
    mandate: EmbeddableMollieMandateDto;
}

export interface MollieWebhookEvent {
    id: string;
}

export interface PageDto {
    meta: PageMetaDto;
}

export interface PageMetaDto {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    page: number;
    pageCount: number;
    perPage: number;
    totalCount: number;
}

export interface PaginatedSubscriptionCustomerDto {
    birthPlace: string;
    /** @format date-time */
    birthdate: string;
    email: string;
    firstName: string;
    fullName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export interface PaginatedSubscriptionDto {
    amountInclTax: number;
    /** @format date-time */
    createdAt: string;
    customerAllowsRenewal: boolean;
    customers: PaginatedSubscriptionCustomerDto[];
    /** @format date-time */
    deletedAt: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    endCoverDate: string;
    externalId: string;
    id: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    insuranceDate: string;
    reference: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    startCoverDate: string;
    /** @example "active" */
    status: 'active' | 'pending' | 'voided' | 'refunded' | 'failed' | 'expired';
    subscriptionVoid: SubscriptionVoidDto;
}

export interface PaymentContextDto {
    data?: object;
    method: PaymentMethodType;
}

export interface PaymentMeanDto {
    /** @format date-time */
    createdAt: string;
    customers: CustomerDto;
    /** @format date-time */
    deletedAt: string;
    externalProviderLatestResponse?: string;
    id: string;
    metadata?: MollieMetadataDto | CheckoutMetadataDto | StripeMetadataDto;
    paymentMethod?: PaymentMethodDto;
    paymentMethodFieldValues?: FieldValueDto[];
    status: PaymentMeanStatus;
    /** @example "checkout_credit_card" */
    type: PaymentMeanType;
}

export interface PaymentMeanProcedureDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    events: ProcedureEventDto[];
    id: string;
    isMandatory: boolean;
    metadata?: PaymentMeanProcedureMetadataDto;
    paymentMeanId: string;
    redirectUrls: RedirectUrlsDto | null;
    /** @example "Completed" */
    status: ProcedureStatus;
    /** @example "VerifyCheckoutPaymentMean" */
    type: PaymentMeanProcedureType;
}

export interface PaymentMeanProcedureMetadataDto {
    checkoutPaymentId?: string;
    checkoutPaymentUrl?: string;
}

export enum PaymentMeanProcedureType {
    VerifyCheckoutPaymentMean = 'VerifyCheckoutPaymentMean',
    AssignPaymentMeanToSubscriptions = 'AssignPaymentMeanToSubscriptions',
}

export enum PaymentMeanStatus {
    Verified = 'Verified',
    Pending = 'Pending',
    Rejected = 'Rejected',
}

export enum PaymentMeanType {
    CheckoutCreditCard = 'checkout_credit_card',
    Stripe = 'stripe',
    MollieSepaMandate = 'mollie_sepa_mandate',
    Custom = 'custom',
}

export interface PaymentMethodDto {
    disabled3DS: boolean;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    fields: FieldDto[];
    id: string;
    name: string;
    salesChannels: string[];
    type: PaymentMethodType;
}

export enum PaymentMethodType {
    Cash = 'Cash',
    CreditCard = 'CreditCard',
    Stripe = 'Stripe',
    Sepa = 'Sepa',
    Custom = 'Custom',
}

export interface PdfResponseServiceFieldValuesDto {
    name: string;
    value: string;
}

export interface PdfToSubscriptionDataDto {
    files: object[];
}

export interface PdfToSubscriptionDataResponseDto {
    customer: CreateCustomerDto;
    serviceFields: PdfResponseServiceFieldValuesDto[];
}

export interface PeriodOptionsDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface PriceProductDto {
    amount: number;
}

export interface PricingDto {
    amount: number;
    computedFields: ComputedFieldDto[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    pricingRules: PricingRuleDto[];
}

export interface PricingRuleDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    rank: number;
    rule: RuleDto;
}

export interface ProcedureEventDto {
    /** @format date-time */
    date: string;
    details?: string;
    message?: string;
    /** @example "Started" */
    value: ProcedureStatus;
}

export enum ProcedureStatus {
    Pending = 'Pending',
    Started = 'Started',
    Completed = 'Completed',
    Failed = 'Failed',
}

export interface ProductClaimLinkDto {
    claimLink?: string;
    originalVersion: string;
    product: string;
}

export interface ProductConfigExportDetails {
    ids: string[];
    include: ProductConfigPart[];
}

export enum ProductConfigPart {
    Services = 'services',
    Pricings = 'pricings',
    Documents = 'documents',
}

export interface ProductDto {
    backdatingDays: number;
    beneficiariesNumber: number;
    carenceDays: number;
    certificateType: CertificateType;
    compensationArea?: string | null;
    coverArea?: string | null;
    coveredCategories?: string[];
    description: string | null;
    endCoverDateRule?: CoverDateRuleDto;
    exclusionArea?: string | null;
    files: FileDto[];
    hasExternalId: boolean;
    id: string;
    legalMentions?: string;
    maxCoveredPrice?: number;
    multiplyByBeneficiaries: boolean;
    name: string;
    ocrUri?: string;
    originalId: string;
    pricings: PricingDto[];
    protectionDays: number;
    retractPeriod: number;
    security: string | null;
    services: ServiceDto[];
    startCoverDateRule?: CoverDateRuleDto;
    status: ProductStatus;
    tacitRenewalCycleDays: number;
    tacitRenewalCycles: number;
    tags: TagDto[];
    technicalName?: string | null;
    trialDays: number;
    uncoveredArea?: string | null;
    voidFormUri?: string;
}

export interface ProductFilesDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    documentType: 'document' | 'fic-document' | 'notice-document' | 'ipid-document';
    file: string;
    id: string;
    product: string;
}

export enum ProductStatus {
    Enabled = 'enabled',
    Disabled = 'disabled',
    Draft = 'draft',
}

export interface ProviderAccountDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    providerId: string;
}

export interface ProviderDto {
    address: AddressDto;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    description: string;
    id: string;
    legalTerms: LegalTermsDto | null;
    name: string;
    renunciationRights: string;
}

export interface RedirectUrlsDto {
    failure?: string;
    success: string;
}

export interface RegenerateCertificateDto {
    sendEmail: boolean;
}

export interface RegisterAdminDto {
    adminInvitationId: string;
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
}

export interface RequestAddErrorToImportLogDto {
    contractDeterminationErrorsCount?: number;
    fetchErrorsCount?: number;
    fetchedDataCount?: number;
    mappingErrorsCount?: number;
    skippedDataCount?: number;
    skippedDataInScopeCount?: number;
    standardImportErrorsCount?: number;
}

export interface RequestAddFileImportLogDto {
    extension: FilesExtensions;
    mimeType: FilesMimeTypes;
    name: string;
    path: string;
}

export interface RequestCreateDataProviderImportLogDto {
    contractDeterminationErrorsCount?: number;
    dataProviderName: string;
    fetchErrorsCount?: number;
    fetchedDataCount?: number;
    mappingErrorsCount?: number;
    skippedDataCount?: number;
    skippedDataInScopeCount?: number;
    standardImportErrorsCount?: number;
}

export interface RequestUpdateClaimPropertyMappingsDto {
    claimAmount?: string;
    claimDeclarationDate?: string;
    claimEventDate?: string;
    claimReference?: string;
    claimStatus?: string;
    customerBic?: string;
    customerEmail?: string;
    customerFirstName?: string;
    customerIban?: string;
    customerLastName?: string;
    productName?: string;
    subscriptionDate?: string;
    subscriptionId?: string;
}

export interface ResetPasswordDto {
    confirmPassword: string;
    forgottenPasswordToken: string;
    password: string;
}

export interface ResponseClaimPropertyMappingDto {
    claimAmount?: string;
    claimDeclarationDate?: string;
    claimEventDate?: string;
    claimReference?: string;
    claimStatus?: string;
    customerBic?: string;
    customerEmail?: string;
    customerFirstName?: string;
    customerIban?: string;
    customerLastName?: string;
    id?: string;
    productName?: string;
    subscriptionDate?: string;
    subscriptionId?: string;
}

export interface ResponseCreateDataProviderImportLogDto {
    contractDeterminationErrorsCount: number;
    dataProvider: DataProviderDto;
    fetchErrorsCount: number;
    fetchedDataCount: number;
    id: string;
    mappingErrorsCount: number;
    skippedDataCount: number;
    skippedDataInScopeCount: number;
    standardImportErrorsCount: number;
}

export interface RuleCheckDto {
    conditionValue: string | number | boolean;
    fieldId: string;
    fieldType: 'SERVICE_FIELD' | 'COMPUTED_FIELD';
    operator: 'eq' | 'gt' | 'gte' | 'lt' | 'lte';
}

export interface RuleDto {
    checks: RuleCheckDto[];
    operation: 'addition' | 'substraction' | 'percent' | 'multiply' | 'divide' | 'fixed';
    operationServiceFieldId?: string;
    resultValue: number;
}

export interface SalesChannelContractDto {
    id: string;
    product: SalesChannelContractProductDto;
}

export interface SalesChannelContractProductDto {
    id: string;
    name: string;
}

export interface SalesChannelContractStoreDto {
    id: string;
    name: string;
}

export interface SalesChannelContractsItemDto {
    commissionRate: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    docusignId: string | null;
    /** @format date-time */
    endAt: string;
    files: FileDto[];
    id: string;
    managementFees: number;
    product: SalesChannelContractsItemProductDto;
    showPriceOnCertificate: boolean;
    /** @format date-time */
    startAt: string;
    status: 'Active' | 'AwaitingSignature' | 'Cancelled';
    store: SalesChannelContractStoreDto;
}

export interface SalesChannelContractsItemProductDto {
    backdatingDays: number;
    beneficiariesNumber: number;
    carenceDays: number;
    certificateType: CertificateType;
    description: string | null;
    files: FileDto[];
    id: string;
    name: string;
    pricings: SalesChannelContractsItemProductPricingDto[];
    protectionDays: number;
    retractPeriod: number;
    services: SalesChannelContractsItemProductServiceDto[];
    trialDays: number;
}

export interface SalesChannelContractsItemProductPricingDto {
    amount: number;
    id: string;
}

export interface SalesChannelContractsItemProductServiceDto {
    billingCyclePeriod: string;
    id: string;
    name: string;
    serviceFields: FieldDto[];
}

export interface SalesChannelPaymentMethodDto {
    disabled3DS: boolean;
    id: string;
    name: string;
    type: PaymentMethodType;
}

export interface SalesChannelsDto {
    askEmailVerificationCode: boolean;
    contracts: SalesChannelContractDto[];
    dataProvider?: string;
    dataProviderConfiguration?:
        | EseasonConfigurationDto
        | AudiowizardConfigurationDto
        | InaxelConfigurationDto
        | MaevaConfigurationDto;
    disabled: boolean;
    id: string;
    isCertificateGenerationDisabled: boolean;
    isDisplayedInApp: boolean;
    isSendEmailEnabled: boolean;
    name: string;
    paymentMethods: string[];
    rank: string;
    requestDocumentsSignature: boolean;
    store: string;
    type: 'WebStore' | 'PointOfSales';
    verifyPrice: boolean;
}

export interface SalesChannelsWithPaymentMethodDto {
    askEmailVerificationCode: boolean;
    contracts: string[];
    /** @format date-time */
    createdAt: string;
    dataProvider: string | null;
    dataProviderConfiguration?:
        | EseasonConfigurationDto
        | AudiowizardConfigurationDto
        | InaxelConfigurationDto
        | MaevaConfigurationDto;
    /** @format date-time */
    deletedAt: string;
    disabled: boolean;
    id: string;
    isCertificateGenerationDisabled: boolean;
    isSendEmailEnabled: boolean;
    name: string;
    paymentMethods: SalesChannelPaymentMethodDto[];
    rank: string;
    requestDocumentsSignature: boolean;
    store: string;
    type: 'WebStore' | 'PointOfSales';
}

export interface SendSelectedInvoicesByMailDto {
    ids?: string[];
}

export interface SepaPaymentContextDto {
    data: SepaPaymentDataDto;
    method: 'Sepa';
}

export interface SepaPaymentDataDto {
    iban: string;
}

export interface ServiceAccountAuthDto {
    clientId: string;
    clientSecret: string;
}

export interface ServiceAccountClaimCompensationDto {
    amount: number;
    status: ClaimCompensationStatus;
}

export interface ServiceAccountClaimDto {
    claimClosures?: ClaimClosureDto[];
    compensations?: ServiceAccountClaimCompensationDto[];
    /** @format date-time */
    declarationDate?: string;
    email?: string | null;
    /** @format date-time */
    eventDate?: string | null;
    externalFieldValues?: ExternalClaimFieldValueDto[];
    id: string;
    phoneNumber?: string | null;
    reason: string;
    reference: string;
    /** @example "New" */
    status: ClaimStatus;
    subscriptionsIds: string[];
}

export interface ServiceAccountDto {
    clientId: string;
    clientSecret: string;
    id: string;
}

export interface ServiceConfigExportDetails {
    ids: string[];
    include: ServiceConfigPart[];
}

export enum ServiceConfigPart {
    ServiceFields = 'serviceFields',
}

export interface ServiceDto {
    billingCycleCount: number;
    billingCyclePeriod: 'one_shot' | 'month';
    claimText?: string;
    fields: FieldDto[];
    id: string;
    legalMentions?: string;
    name: string;
    priceFixAmount: number;
    pricePercent: number;
    provider: ServiceProviderDto;
    /** @deprecated */
    serviceFields: FieldDto[];
    tax: number;
}

export interface ServiceProviderDto {
    id: string;
    name: string;
}

export interface SetAudiowizardConfigurationDto {
    laboratoryId: string;
    type: 'audiowizard';
}

export interface SetContractDocumentTemplateDto {
    documentTemplateId: string;
    templateType: 'SubscriptionCertificate' | 'SubscriptionDocumentToSign';
}

export interface SetEseasonConfigurationDto {
    campingId: string;
    type: 'eseason';
}

export interface SetInaxelConfigurationDto {
    password: string;
    serverIp: string;
    type: 'inaxel';
    userName: string;
}

export interface SetMaevaConfigurationDto {
    type: 'maeva';
}

export interface SettingsDto {
    emailFrom: string;
    faviconUrl: string;
    logoUrl: string;
    primaryColor: string;
    secondaryColor: string;
    whiteBrandEmail: boolean;
}

export interface StoreBillingInformationsDto {
    autoGeneration: boolean;
    autoMail: boolean;
    autoOptimisation: boolean;
    bic?: string | null;
    billingEmail: string[];
    billingIban?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    overrideCompanyName?: string;
}

export interface StoreByUrlDto {
    address: AddressDto;
    bringUpSubscriptionsToParentStore: boolean;
    /** @format date-time */
    createdAt: string;
    dealerRole: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    isGenericCustomerSpaceEnabled?: boolean;
    legalName: string;
    legalTerms?: LegalTermsDto;
    name: string;
    parentStore?: StoreLiteDto;
    settings?: SettingsDto;
    validationStatus?: ValidationStatus;
    websiteUrl?: string;
    websites?: WebsiteDto[];
}

export interface StoreChildDto {
    address: AddressDto;
    bringUpSubscriptionsToParentStore: boolean;
    /** @format date-time */
    createdAt: string;
    /** @example "Seller" */
    dealerRole: DealerRole;
    dealerStores: DealerStoreDto[];
    /** @format date-time */
    deletedAt: string;
    id: string;
    legalName: string;
    legalTerms?: LegalTermsDto;
    name: string;
    parentStore: StoreLiteDto;
    salesChannels: SalesChannelsDto[];
    sendImportNotification: boolean;
    settings: SettingsDto;
    /** @example "Validated" */
    validationStatus: ValidationStatus;
    websiteUrl: string;
}

export interface StoreClaimDto {
    claimClosures?: ClaimClosureDto[];
    /** @format date-time */
    declarationDate?: string;
    fullName?: string;
    id: string;
    productName?: string;
    reference: string;
    status: ClaimStatus;
    subscriptionId?: string;
    subscriptionReference?: string;
}

export interface StoreConfigExportDetails {
    ids: string[];
    include: StoreConfigPart[];
}

export enum StoreConfigPart {
    Customers = 'customers',
    Contracts = 'contracts',
    Subscriptions = 'subscriptions',
    SalesChannels = 'salesChannels',
    PaymentMethods = 'paymentMethods',
    Websites = 'websites',
    Dealers = 'dealers',
    ServiceAccounts = 'serviceAccounts',
}

export interface StoreContractDto {
    commissionRate: number;
    id: string;
    product: StoreContractProductDto;
    status: ContractStatus;
}

export interface StoreContractProductDto {
    id: string;
    name: string;
}

export interface StoreDto {
    address: AddressDto;
    bringUpSubscriptionsToParentStore: boolean;
    /** @format date-time */
    createdAt: string;
    dealerRole: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    isGenericCustomerSpaceEnabled: boolean;
    legalName: string;
    legalTerms?: LegalTermsDto;
    name: string;
    parentStore: StoreLiteDto;
    salesChannels: StoreSalesChannelDto[];
    sendImportNotification: boolean;
    settings: SettingsDto;
    validationStatus: 'Pending' | 'Validated' | 'Rejected';
    websiteUrl: string;
}

export interface StoreInvoiceDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    datePaid?: string;
    /** @format date-time */
    deletedAt: string;
    distributorCommissionAmount: number;
    /** @format date-time */
    from: string;
    id: string;
    invoiceCount: number;
    isSelfCharged: boolean;
    payerName?: string;
    reference: string;
    status: StoreInvoiceStatus;
    store: StoreInvoiceStoreDto;
    taxAmount: number;
    /** @format date-time */
    to: string;
    totalAmountExclTax: number;
    totalAmountInclTax: number;
}

export enum StoreInvoiceStatus {
    Pending = 'pending',
    Paid = 'paid',
    Cancelled = 'cancelled',
    Sent = 'sent',
    Erroneous = 'erroneous',
    Verified = 'verified',
    SentError = 'sent_error',
    PartialPaid = 'partial_paid',
    DoublePaid = 'double_paid',
}

export interface StoreInvoiceStoreDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    name: string;
}

export interface StoreInvoiceTransactionDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    currency: CurrencyCode;
    customerFirstName: string;
    customerLastName: string;
    /** @format date-time */
    deletedAt: string;
    externalId: string;
    id: string;
    provider: TransactionProvider;
    status: TransactionStatus;
    subscriptionId: string;
    subscriptionInvoiceId: string;
}

export interface StoreLiteDto {
    id: string;
    legalName?: string;
    name: string;
    orias?: string;
    siret?: string;
    validationStatus: 'Pending' | 'Validated' | 'Rejected';
}

export interface StoreSalesChannelDto {
    contractIds: string[];
    dataProvider: string | null;
    id: string;
    name: string;
}

export interface StoreSubscriptionContractDto {
    id: string;
    product: StoreSubscriptionContractProductDto;
}

export interface StoreSubscriptionContractProductDto {
    id: string;
    name: string;
    services: StoreSubscriptionContractProductServiceDto[];
}

export interface StoreSubscriptionContractProductServiceDto {
    billingCyclePeriod: BillingCyclePeriodEnum;
    id: string;
    name: string;
    tax: number;
}

export interface StoreSubscriptionDto {
    amountInclTax: number;
    contract: StoreSubscriptionContractDto;
    /** @format date-time */
    createdAt: string;
    customers: CustomerDto[];
    dealer: DealerDto;
    /** @format date-time */
    deletedAt: string;
    /** @format date-time */
    endCoverDate: string;
    externalId?: string;
    id: string;
    /** @format date-time */
    insuranceDate: string;
    isInTrial: boolean;
    reference: string;
    salesChannel: StoreSubscriptionSalesChannelDto;
    serviceFieldValues?: FieldValueDto[];
    /** @format date-time */
    startCoverDate: string;
    status: SubscriptionStatus;
}

export interface StoreSubscriptionSalesChannelDto {
    id: string;
    name: string;
}

export interface StoreWebhookDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    name: string;
}

export interface StripeCardDto {
    last4: string;
    brand: string;
    exp_month: number;
    exp_year: number;
}

export interface StripeCreateSetupIntentResponseDto {
    /** @example "seti_SECRET" */
    clientSecret: string;
}

export interface StripeMetadataDto {
    paymentMethod: StripePaymentMethodDto;
    paymentMethodId: string;
}

export interface StripePaymentContextDto {
    data: StripePaymentDataDto;
    method: 'Stripe';
}

export interface StripePaymentDataDto {
    stripePaymentMethod: StripePaymentMethodDto;
}

export interface StripePaymentMethodDto {
    card?: StripeCardDto | null;
    customer?: string | null;
    id: string;
    /** @example "card" */
    type: string;
}

export interface SubscribeFieldsContractDto {
    id: string;
    status: ContractStatus;
}

export interface SubscribeFieldsDto {
    contract: SubscribeFieldsContractDto;
    files: SubscribeFieldsPublicFileLinkDto[];
    paymentMethods: SubscriptionFieldsPaymentMethodDto[];
    product: SubscribeFieldsProductDto;
    salesChannel: SubscribeFieldsSalesChannelDto;
    serviceFields: SubscribeFieldsServiceFieldDto[];
    services: SubscribeFieldsServiceDto[];
}

export interface SubscribeFieldsProductDto {
    compensationArea: string;
    coverArea: string;
    description: string;
    exclusionArea: string;
    id: string;
    name: string;
    protectionDays: number;
    uncoveredArea: string;
}

export interface SubscribeFieldsPublicFileLinkDto {
    name: string;
    publicUrl: string;
    type: string;
}

export interface SubscribeFieldsSalesChannelDto {
    id: string;
}

export interface SubscribeFieldsServiceDto {
    id: string;
    name: string;
}

export interface SubscribeFieldsServiceFieldDto {
    description: string;
    id: string;
    name: string;
    options: string[];
    rank: number;
    required: boolean;
    title: string;
    type: string;
}

export enum SubscriptionBillingStatus {
    Offered = 'offered',
    ToPay = 'toPay',
}

export interface SubscriptionBillingTimelineDto {
    amount: number;
    /**
     * @format date
     * @example "2022-03-25T00:00:00.000Z"
     */
    from: string;
    /**
     * @format integer
     * @example 1
     */
    index: number;
    /**
     * @format date
     * @example "2022-03-25T00:00:00.000Z"
     */
    paymentDate: string;
    /**
     * @format integer
     * @example 1
     */
    periodIndex: number;
    status: SubscriptionBillingStatus;
    /**
     * @format date
     * @example "2022-03-25T00:00:00.000Z"
     */
    to: string;
}

export interface SubscriptionCashPaymentContextDto {
    method: 'Cash';
    paymentMethodId: string;
}

export interface SubscriptionCheckoutPaymentContextDto {
    data: CheckoutPaymentDataDto;
    method: 'CreditCard';
    paymentMethodId: string;
}

export interface SubscriptionContractDto {
    commissionRate: number;
    files: FileDto[];
    id: string;
    name: string;
    product: SubscriptionContractProductDto;
    store: SubscriptionContractStoreDto;
}

export interface SubscriptionContractProductDto {
    carenceDays: number;
    claimLink?: string;
    description: string | null;
    files: FileDto[];
    id: string;
    name: string;
    protectionDays: number;
    retractPeriod: number;
    services: SubscriptionContractProductServiceDto[];
    tacitRenewalCycleDays: number;
    tacitRenewalCycles: number;
    trialDays: number;
    voidFormUri?: string;
}

export interface SubscriptionContractProductServiceDto {
    billingCyclePeriod: string;
    fields: FieldDto[];
    id: string;
    name: string;
    tax: number;
}

export interface SubscriptionContractStoreDto {
    id: string;
    name: string;
}

export interface SubscriptionCustomPaymentContextDto {
    method: 'Custom';
    paymentMethodFieldValues: string[];
    paymentMethodId: string;
}

export interface SubscriptionDto {
    amountInclTax: number;
    beneficiaries: BeneficiaryDto[];
    contract: SubscriptionContractDto;
    /** @format date-time */
    createdAt: string;
    customerAllowsRenewal: boolean;
    customers: CustomerDto[];
    /** @format date-time */
    deletedAt: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    endCoverDate: string;
    externalId: string;
    files: FileDto[];
    id: string;
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    insuranceDate: string;
    invoices: SubscriptionInvoiceDto[];
    isInTrial: boolean;
    links?: EmbeddableLinksDto;
    procedures: SubscriptionProcedureDto[] | null;
    reference: string;
    salesChannel: SubscriptionSalesChannelDto;
    serviceFieldValues?: FieldValueDto[];
    /**
     * @format date-time
     * @example "2022-03-25T00:00:00.000Z"
     */
    startCoverDate: string;
    /** @example "active" */
    status: SubscriptionStatus;
    subscriptionVoid: SubscriptionVoidDto;
    transactions: TransactionDto[];
    utmData: SubscriptionUtmDataDto;
}

export interface SubscriptionFieldsPaymentMethodDto {
    id: string;
    name: string;
    type: string;
}

export interface SubscriptionInvoiceDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    /** @format date-time */
    invoiceDate: string;
    paidAmount: number;
    transactions: TransactionDto[];
}

export interface SubscriptionPaymentContextDto {
    data?: object;
    method: PaymentMethodType;
    paymentMethodId: string;
}

export interface SubscriptionPriceResponseDto {
    amount: number;
    contractId: string;
    serviceFieldValues: string[];
}

export interface SubscriptionProcedureDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    events: ProcedureEventDto[];
    id: string;
    isMandatory: boolean;
    metadata: SubscriptionProcedureMetadataDto;
    redirectUrls: RedirectUrlsDto | null;
    /** @example "Completed" */
    status: ProcedureStatus;
    subscriptionId: string;
    /** @example "SignSepaMandate" */
    type: SubscriptionProcedureType;
}

export interface SubscriptionProcedureMetadataDto {
    checkoutPaymentId?: string;
    checkoutPaymentUrl?: string;
    emailIds?: string[];
    yousignProcedureId?: string;
    yousignProcedureUrl?: string;
}

export enum SubscriptionProcedureType {
    SignSepaMandate = 'SignSepaMandate',
    CreateMollieSepaMandate = 'CreateMollieSepaMandate',
    SignPrecontractualDocuments = 'SignPrecontractualDocuments',
    GenarateCertificate = 'GenarateCertificate',
    ValidateCheckoutPayment = 'ValidateCheckoutPayment',
    ValidateStripePayment = 'ValidateStripePayment',
    SendSubscriptionValidationEmail = 'SendSubscriptionValidationEmail',
    GenerateSubscriptionBillingTimeline = 'GenerateSubscriptionBillingTimeline',
}

export interface SubscriptionSalesChannelDto {
    id: string;
    isSendEmailEnabled: boolean;
    name: string;
}

export interface SubscriptionSepaPaymentContextDto {
    data: SepaPaymentDataDto;
    method: 'Sepa';
    paymentMethodId: string;
}

export enum SubscriptionStatus {
    Active = 'active',
    Pending = 'pending',
    Voided = 'voided',
    Refunded = 'refunded',
    Failed = 'failed',
    Expired = 'expired',
}

export interface SubscriptionStripePaymentContextDto {
    data: StripePaymentDataDto;
    method: 'Stripe';
    paymentMethodId: string;
}

export interface SubscriptionUsedPaymentMeanDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    metadata: MollieMetadataDto | CheckoutMetadataDto;
    status: string;
    type: PaymentMeanType;
}

export interface SubscriptionUtmDataDto {
    campaign?: string;
    content?: string;
    medium?: string;
    source?: string;
    term?: string;
}

export interface SubscriptionVoidDto {
    id: string;
    voidReason: string;
}

export interface TagDto {
    id?: string;
    key?: string;
    value: string;
}

export interface TextElementDto {
    text: string;
}

export interface TicketacBeneficiaryDto {
    email: string;
    lastname: string;
    name: string;
    phone_number: string;
    phone_number_country: string;
    postalcode: string;
}

export interface TransactionDto {
    amount: number;
    /** @format date-time */
    createdAt: string;
    currency: CurrencyCode;
    /** @format date-time */
    deletedAt: string;
    externalId: string;
    externalProviderResponse?: string;
    id: string;
    provider: TransactionProvider;
    status: TransactionStatus;
    subscriptionId: string;
    subscriptionInvoiceId: string;
}

export enum TransactionProvider {
    Checkout = 'checkout',
    Stripe = 'stripe',
    Neat = 'neat',
    Mollie = 'Mollie',
    Custom = 'Custom',
}

export enum TransactionStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
    Voided = 'voided',
    Offered = 'offered',
}

export interface UpdateAdminDto {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface UpdateAdminRoleDto {
    adminId: string;
    /** @example "SuperAdmin" */
    role: AdminRole;
}

export interface UpdateBeneficiaryDto {
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    firstName: string;
    id: string;
    lastName: string;
}

export interface UpdateBrokerFeeRulesDto {
    amount: number;
    id?: string;
    operation: 'addition' | 'substraction' | 'percent' | 'multiply' | 'divide' | 'fixed';
    pricingRuleId: string;
}

export interface UpdateClaimAssigned {
    assignedId: string;
}

export interface UpdateClaimDto {
    claimFieldValues: UpdateClaimFieldValuesDto[];
    /** @format date-time */
    eventDate: string;
}

export interface UpdateClaimFieldValuesDto {
    id: string;
    value?: string | boolean | number;
}

export interface UpdateClaimFieldsDto {
    claimFields: CreateOrUpdateClaimFieldDto[];
}

export interface UpdateClaimStatusDto {
    closureReason?: ClaimClosureReason;
    newStatus: ClaimStatus;
}

export interface UpdateClaimSubscriptionsDto {
    subscriptionIds: string[];
}

export interface UpdateCompensationDto {
    amount?: number;
    bic?: string;
    iban?: string;
}

export interface UpdateContractDto {
    brokerFeeRules: UpdateBrokerFeeRulesDto[];
    commissionRate: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    /** @format date-time */
    endAt?: string;
    files: FileDto[] | null;
    id: string;
    managementFees: number;
    newFiles: object[] | null;
    newFilesExtraData: FileExtraDataDto[] | null;
    showPriceOnCertificate: boolean;
    /** @format date-time */
    startAt: string;
    status: ContractStatus;
}

export interface UpdateContractMailTemplateDto {
    cci?: string[];
    templateId: string;
    type: string;
}

export interface UpdateContractMailTemplatesDto {
    contractMailTemplates: UpdateContractMailTemplateDto[];
}

export interface UpdateCustomerDto {
    address: AddressDto;
    birthPlace?: string;
    /** @format date-time */
    birthdate?: string;
    customData?: CustomCustomerDataDto;
    firstName: string;
    flags?: UpdateCustomerFlagsDto;
    id: string;
    lastName: string;
    phoneNumber: string;
}

export interface UpdateCustomerFlagsDto {
    isOptinEmail?: boolean;
    isOptinPhone?: boolean;
}

export interface UpdateDataProviderDto {
    connector: DataProviderConnector;
    dataProviderFields?: UpdateDataProviderFieldDto[];
    id: string;
    name: string;
}

export interface UpdateDataProviderFieldDto {
    id?: string;
    type: DataProviderFieldType;
    value: string;
}

export interface UpdateDealerDto {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface UpdateDealerRoleDto {
    /** @example "User" */
    role: DealerRole;
    storeId: string;
}

export interface UpdateDocumentTemplateDto {
    content: GenericElementDto[];
    name: string;
}

export interface UpdateFieldValueDto {
    id: string;
    value?: string | boolean | number | null;
}

export interface UpdateMailTemplateDto {
    name: string;
    templateId: string;
    /** @example "magic_link" */
    type: MailTemplateType;
}

export interface UpdateMultipleStoreInvoiceStatusDto {
    status: StoreInvoiceStatus;
    storeInvoiceIds: string[];
}

export interface UpdatePaymentMeanDto {
    paymentMethodFieldValues: CreateFieldValueDto[];
}

export interface UpdatePaymentMethodDto {
    disabled3DS: boolean;
    fields?: CreateOrUpdateFieldDto[];
    name: string;
    type: PaymentMethodType;
}

export interface UpdateProductClaimLinkDto {
    claimLink: string | null;
}

export interface UpdateProductCoverageDto {
    backdatingDays?: number;
    coveredCategories?: string[];
    endCoverDateRule?: CreateCoverDateRuleDto | null;
    maxCoveredPrice?: number;
    startCoverDateRule?: CreateCoverDateRuleDto | null;
}

export interface UpdateProductDto {
    beneficiariesNumber: number;
    carenceDays: number;
    certificateType: CertificateType;
    compensationArea?: string | null;
    coverArea?: string | null;
    coveredCategories?: string[];
    description?: string | null;
    exclusionArea?: string | null;
    files?: FileDto[];
    hasExternalId: boolean;
    maxCoveredPrice?: number;
    multiplyByBeneficiaries: boolean;
    name: string;
    newFiles: object[] | null;
    newFilesExtraData: FileExtraDataDto[] | null;
    ocrUri?: string;
    protectionDays: number;
    retractPeriod: number;
    security: string | null;
    services: string[];
    status: ProductStatus;
    tacitRenewalCycleDays: number;
    tacitRenewalCycles: number;
    tags: TagDto[];
    technicalName?: string | null;
    trialDays: number;
    uncoveredArea?: string | null;
    voidFormUri?: string;
}

export interface UpdateProductPricingDto {
    pricings: CreateOrUpdatePricingDto[];
}

export interface UpdateProviderDto {
    address: AddressDto;
    description: string | null;
    legalTerms: LegalTermsDto | null;
    name: string;
    renunciationRights: string;
}

export interface UpdateSalesChannelsDto {
    /** @default false */
    askEmailVerificationCode?: boolean;
    contracts: string[];
    dataProvider?: string;
    dataProviderConfiguration?:
        | SetAudiowizardConfigurationDto
        | SetEseasonConfigurationDto
        | SetInaxelConfigurationDto
        | SetMaevaConfigurationDto;
    disabled: boolean;
    id: string;
    /** @default false */
    isCertificateGenerationDisabled?: boolean;
    isDisplayedInApp: boolean;
    /** @default false */
    isSendEmailEnabled?: boolean;
    name: string;
    paymentMethods: string[];
    rank: string;
    /** @default false */
    requestDocumentsSignature: boolean;
    type: 'WebStore' | 'PointOfSales';
    /** @default false */
    verifyPrice?: boolean;
}

export interface UpdateServiceDto {
    billingCycleCount: number;
    billingCyclePeriod: 'one_shot' | 'month';
    claimText?: string;
    fields?: CreateOrUpdateFieldDto[];
    legalMentions?: string;
    name: string;
    priceFixAmount: number;
    pricePercent: number;
    provider: string;
    tax: number;
}

export interface UpdateServiceFieldsValuesDto {
    serviceFieldValues: UpdateFieldValueDto[];
}

export interface UpdateStoreBillingInformationsDto {
    autoGeneration: boolean;
    autoMail: boolean;
    autoOptimisation: boolean;
    bic: string | null;
    billingEmail: string[];
    billingIban: string | null;
    id: string | null;
    overrideCompanyName?: string;
}

export interface UpdateStoreConfigurationDto {
    bringUpSubscriptionsToParentStore: boolean;
    settings: SettingsDto;
}

export interface UpdateStoreInformationsDto {
    address: AddressDto;
    legalName: string;
    legalTerms: LegalTermsDto;
    name: string;
    sendImportNotification: boolean;
    validationStatus: 'Pending' | 'Validated' | 'Rejected';
    websiteUrl: string;
}

export interface UpdateStoreInvoiceDetailsDto {
    /** @format date-time */
    datePaid: string;
    payerName: string;
}

export interface UpdateStoreInvoiceStatusDto {
    status: StoreInvoiceStatus;
}

export interface UpdateSubscriptionsContractDto {
    newContractId: string;
    oldContractId: string;
    subscriptionIds: string[];
}

export interface UpdateTacitRenewalDto {
    customerAllowsRenewal: boolean;
}

export interface UpdateTransactionBatchDto {
    /** @example "3e996391-87b6-4123-bec8-75c9ef17bd43" */
    id: string;
    /** @example "success" */
    status: TransactionStatus;
}

export interface UpdateTransactionByBatchDto {
    data: UpdateTransactionBatchDto[];
}

export interface UpdateTransactionByBatchResponseDto {
    errors: string[];
    updated: string[];
}

export interface UpdateWebhookDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    /** @example "subscription:created" */
    events: WebhookEvent[];
    id: string;
    isActive: boolean;
    store: string;
    url: string;
}

export interface UpdateWebsiteDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    magicLinkMailTemplateId?: string;
    magicLinkRedirectUrl?: string;
    name: string;
    /** @example "salesRoute" */
    type: WebsiteType;
    url: string;
}

export enum ValidationStatus {
    Pending = 'Pending',
    Validated = 'Validated',
    Rejected = 'Rejected',
}

export interface VoidSubscriptionDto {
    voidReason: string;
}

export interface WebhookDto {
    attempts: string[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    /** @example "subscription:created" */
    events: WebhookEvent[];
    id: string;
    isActive: boolean;
    sharedKey: string;
    store: StoreWebhookDto;
    url: string;
}

export enum WebhookEvent {
    SubscriptionVoided = 'subscription:voided',
    SubscriptionRefunded = 'subscription:refunded',
    SubscriptionAmended = 'subscription:amended',
    SubscriptionCreated = 'subscription:created',
    ProductMatchingCompleted = 'product-matching:completed',
}

export interface WebhooksAttemptsDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    errors: WebhooksErrorsDto;
    id: string;
    payload: object;
    /** @example "pending" */
    status: string;
    webhook: string;
    webhookEvent: string;
}

export interface WebhooksErrorsDto {
    httpCode:
        | 100
        | 101
        | 102
        | 103
        | 200
        | 201
        | 202
        | 203
        | 204
        | 205
        | 206
        | 300
        | 301
        | 302
        | 303
        | 304
        | 307
        | 308
        | 400
        | 401
        | 402
        | 403
        | 404
        | 405
        | 406
        | 407
        | 408
        | 409
        | 410
        | 411
        | 412
        | 413
        | 414
        | 415
        | 416
        | 417
        | 418
        | 421
        | 422
        | 424
        | 428
        | 429
        | 500
        | 501
        | 502
        | 503
        | 504
        | 505;
    message: string;
}

export interface WebsiteDto {
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    deletedAt: string;
    id: string;
    magicLinkMailTemplate?: MailTemplateDto;
    magicLinkRedirectUrl?: string;
    name: string;
    store: string;
    /** @example "salesRoute" */
    type: WebsiteType;
    url: string;
}

export enum WebsiteType {
    SalesRoute = 'salesRoute',
    CustomerSpace = 'customerSpace',
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null,
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig,
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path,
            })
            .then((response) => response.data);
    };
}

/**
 * @title Neat API
 * @version 1.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags root
     * @name Home
     * @request GET:/
     * @response `200` `void`
     */
    home = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/`,
            method: 'GET',
            ...params,
        });

    metrics = {
        /**
         * No description
         *
         * @name MetricsControllerIndex
         * @request GET:/metrics
         * @response `200` `void`
         */
        metricsControllerIndex: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/metrics`,
                method: 'GET',
                ...params,
            }),
    };
    webhooks = {
        /**
         * No description
         *
         * @tags webhooks
         * @name GetWebhookById
         * @request GET:/webhooks/{id}
         * @response `200` `WebhookDto`
         */
        getWebhookById: (id: string, params: RequestParams = {}) =>
            this.request<WebhookDto, any>({
                path: `/webhooks/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks
         * @name DeleteWebhook
         * @request DELETE:/webhooks/{id}/delete
         * @response `200` `void`
         */
        deleteWebhook: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/${id}/delete`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks
         * @name UpdateWebhook
         * @request POST:/webhooks/{id}/update
         * @response `200` `WebhookDto`
         */
        updateWebhook: (id: string, data: UpdateWebhookDto, params: RequestParams = {}) =>
            this.request<WebhookDto, any>({
                path: `/webhooks/${id}/update`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags webhooks
 * @name GetAttempts
 * @request GET:/webhooks/{id}/attempts
 * @response `200` `(PageDto & {
    data?: (WebhooksAttemptsDto)[],

})` Successfully received webhooksattemptsdto list
 */
        getAttempts: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: WebhooksAttemptsDto[];
                },
                any
            >({
                path: `/webhooks/${id}/attempts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/yousign
         * @name UpdateSubscriptionProcedure
         * @request POST:/webhooks/yousign/signature-request
         * @response `201` `void`
         */
        updateSubscriptionProcedure: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/yousign/signature-request`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/yousign
         * @name UpdateSubscriptionProcedure2
         * @request PATCH:/webhooks/yousign/subscription-procedure
         * @originalName updateSubscriptionProcedure
         * @duplicate
         * @response `200` `void`
         */
        updateSubscriptionProcedure2: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/yousign/subscription-procedure`,
                method: 'PATCH',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/yousign
         * @name UpdateYousignSepaMandateProcedure
         * @request PATCH:/webhooks/yousign/sepa-mandate-procedure
         * @response `200` `void`
         */
        updateYousignSepaMandateProcedure: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/yousign/sepa-mandate-procedure`,
                method: 'PATCH',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/checkout
         * @name ConfirmTransactionV3
         * @request POST:/webhooks/checkout/v3
         * @response `404` `void` Transaction not found
         */
        confirmTransactionV3: (params: RequestParams = {}) =>
            this.request<any, void>({
                path: `/webhooks/checkout/v3`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/mollie
         * @name UpdateMolliePayment
         * @request POST:/webhooks/mollie/payment
         * @response `200` `void`
         */
        updateMolliePayment: (data: MollieWebhookEvent, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/mollie/payment`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/stripe
         * @name StripeHandleWebhookEvent
         * @request POST:/webhooks/stripe/event
         * @response `201` `void`
         */
        stripeHandleWebhookEvent: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/stripe/event`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/freshdesk
         * @name CreateNewTicket
         * @request POST:/webhooks/freshdesk/ticket-creation
         * @response `201` `void`
         */
        createNewTicket: (data: FreshdeskTicketNotificationDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/freshdesk/ticket-creation`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags webhooks/freshdesk
         * @name UpdateTicket
         * @request POST:/webhooks/freshdesk/ticket-update
         * @response `200` `void`
         */
        updateTicket: (data: FreshdeskTicketNotificationDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/webhooks/freshdesk/ticket-update`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    stores = {
        /**
 * No description
 *
 * @tags stores
 * @name GetStores
 * @request GET:/stores
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreLiteDto)[],

})` Successfully received storelitedto list
 */
        getStores: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreLiteDto[];
                },
                any
            >({
                path: `/stores`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateStore
         * @request POST:/stores
         * @secure
         * @response `200` `StoreDto`
         * @response `400` `ErrorsDto`
         */
        createStore: (data: CreateStoreDto, params: RequestParams = {}) =>
            this.request<StoreDto, ErrorsDto>({
                path: `/stores`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetAll
         * @request GET:/stores/all
         * @secure
         * @response `200` `(StoreDto)[]`
         */
        getAll: (params: RequestParams = {}) =>
            this.request<StoreDto[], any>({
                path: `/stores/all`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetStoreByWebsiteUrl
         * @request GET:/stores/by-url
         * @secure
         * @response `200` `StoreByUrlDto`
         * @response `404` `void`
         */
        getStoreByWebsiteUrl: (
            query: {
                websiteUrl: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<StoreByUrlDto, void>({
                path: `/stores/by-url`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetStore
         * @request GET:/stores/{id}
         * @secure
         * @response `200` `StoreDto`
         * @response `404` `void`
         */
        getStore: (id: string, params: RequestParams = {}) =>
            this.request<StoreDto, void>({
                path: `/stores/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetWebhooksByStoreId
 * @request GET:/stores/{storeId}/webhooks
 * @secure
 * @response `200` `(PageDto & {
    data?: (WebhookDto)[],

})` Successfully received webhookdto list
 */
        getWebhooksByStoreId: (
            storeId: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: WebhookDto[];
                },
                any
            >({
                path: `/stores/${storeId}/webhooks`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateWebhook
         * @request POST:/stores/{storeId}/webhooks/create
         * @secure
         * @response `200` `WebhookDto`
         */
        createWebhook: (storeId: string, data: CreateWebhookDto, params: RequestParams = {}) =>
            this.request<WebhookDto, any>({
                path: `/stores/${storeId}/webhooks/create`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetAllStoreInvoices
 * @request GET:/stores/{id}/store-invoices
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreInvoiceDto)[],

})` Successfully received storeinvoicedto list
 */
        getAllStoreInvoices: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @format date-time */
                from?: string;
                /** @format date-time */
                to?: string;
                status?: string[];
                isSelfCharged?: boolean;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreInvoiceDto[];
                },
                any
            >({
                path: `/stores/${id}/store-invoices`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetValidStoreInvoices
 * @request GET:/stores/{id}/store-invoices/valid
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreInvoiceDto)[],

})` Successfully received storeinvoicedto list
 */
        getValidStoreInvoices: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @format date-time */
                from?: string;
                /** @format date-time */
                to?: string;
                status?: string[];
                isSelfCharged?: boolean;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreInvoiceDto[];
                },
                any
            >({
                path: `/stores/${id}/store-invoices/valid`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetPaginatedContracts
 * @request GET:/stores/{id}/contracts
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreContractDto)[],

})` Successfully received storecontractdto list
 */
        getPaginatedContracts: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreContractDto[];
                },
                any
            >({
                path: `/stores/${id}/contracts`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateContract
         * @request POST:/stores/{id}/contracts
         * @secure
         * @response `200` `ContractDto`
         * @response `400` `ErrorsDto`
         */
        createContract: (id: string, data: CreateContractDto, params: RequestParams = {}) =>
            this.request<ContractDto, ErrorsDto>({
                path: `/stores/${id}/contracts`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetContracts
         * @request GET:/stores/{id}/contracts/unpaginated
         * @secure
         * @response `200` `(ContractDto)[]`
         */
        getContracts: (id: string, params: RequestParams = {}) =>
            this.request<ContractDto[], any>({
                path: `/stores/${id}/contracts/unpaginated`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetActiveOneShotContracts
         * @request GET:/stores/{id}/contracts/services-fields/unpaginated
         * @secure
         * @response `200` `(ContractWithServiceFieldsDto)[]`
         */
        getActiveOneShotContracts: (id: string, params: RequestParams = {}) =>
            this.request<ContractWithServiceFieldsDto[], any>({
                path: `/stores/${id}/contracts/services-fields/unpaginated`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetStoreUsers
 * @request GET:/stores/{id}/users
 * @secure
 * @response `200` `(PageDto & {
    data?: (DealerWithRoleDto)[],

})` Successfully received dealerwithroledto list
 * @response `404` `void`
 */
        getStoreUsers: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                roles?: DealerRole[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: DealerWithRoleDto[];
                },
                void
            >({
                path: `/stores/${id}/users`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateUser
         * @request POST:/stores/{id}/users
         * @secure
         * @response `200` `DealerDto`
         * @response `404` `void`
         */
        createUser: (id: string, data: CreateDealerStoreDto, params: RequestParams = {}) =>
            this.request<DealerDto, void>({
                path: `/stores/${id}/users`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetChildrenStores
         * @request GET:/stores/{id}/children
         * @secure
         * @response `200` `(StoreChildDto)[]`
         */
        getChildrenStores: (id: string, params: RequestParams = {}) =>
            this.request<StoreChildDto[], any>({
                path: `/stores/${id}/children`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetAllChildrenStores
         * @request GET:/stores/{id}/all-children
         * @secure
         * @response `200` `(StoreLiteDto)[]`
         */
        getAllChildrenStores: (id: string, params: RequestParams = {}) =>
            this.request<StoreLiteDto[], any>({
                path: `/stores/${id}/all-children`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateChildStore
         * @request POST:/stores/{id}/child
         * @secure
         * @response `200` `StoreDto`
         */
        createChildStore: (id: string, data: CreateStoreDto, params: RequestParams = {}) =>
            this.request<StoreDto, any>({
                path: `/stores/${id}/child`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name UpdateInformations
         * @request PATCH:/stores/{storeId}/informations
         * @secure
         * @response `200` `StoreDto`
         * @response `404` `void`
         */
        updateInformations: (
            storeId: string,
            data: UpdateStoreInformationsDto,
            params: RequestParams = {},
        ) =>
            this.request<StoreDto, void>({
                path: `/stores/${storeId}/informations`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name UpdateConfiguration
         * @request PATCH:/stores/{storeId}/configuration
         * @secure
         * @response `200` `StoreDto`
         * @response `404` `void`
         */
        updateConfiguration: (
            storeId: string,
            data: UpdateStoreConfigurationDto,
            params: RequestParams = {},
        ) =>
            this.request<StoreDto, void>({
                path: `/stores/${storeId}/configuration`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetBillingInformations
         * @request GET:/stores/{storeId}/billing-informations
         * @secure
         * @response `200` `StoreBillingInformationsDto`
         */
        getBillingInformations: (storeId: string, params: RequestParams = {}) =>
            this.request<StoreBillingInformationsDto, any>({
                path: `/stores/${storeId}/billing-informations`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name UpdateBillingInformations
         * @request PATCH:/stores/{storeId}/billing-informations
         * @secure
         * @response `200` `StoreBillingInformationsDto`
         * @response `404` `void`
         */
        updateBillingInformations: (
            storeId: string,
            data: UpdateStoreBillingInformationsDto,
            params: RequestParams = {},
        ) =>
            this.request<StoreBillingInformationsDto, void>({
                path: `/stores/${storeId}/billing-informations`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateBillingInformations
         * @request POST:/stores/{storeId}/billing-informations
         * @secure
         * @response `200` `StoreBillingInformationsDto`
         * @response `404` `void`
         */
        createBillingInformations: (
            storeId: string,
            data: CreateStoreBillingInformationsDto,
            params: RequestParams = {},
        ) =>
            this.request<StoreBillingInformationsDto, void>({
                path: `/stores/${storeId}/billing-informations`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateSalesChannel
         * @request POST:/stores/{id}/salesChannel
         * @secure
         * @response `200` `SalesChannelsDto`
         * @response `404` `void`
         */
        createSalesChannel: (
            id: string,
            data: CreateSalesChannelsDto,
            params: RequestParams = {},
        ) =>
            this.request<SalesChannelsDto, void>({
                path: `/stores/${id}/salesChannel`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetStoreSalesChannels
         * @request GET:/stores/{id}/salesChannels
         * @secure
         * @response `200` `(SalesChannelsDto)[]`
         * @response `404` `void`
         */
        getStoreSalesChannels: (
            id: string,
            query?: {
                ignoreDisabled?: boolean;
            },
            params: RequestParams = {},
        ) =>
            this.request<SalesChannelsDto[], void>({
                path: `/stores/${id}/salesChannels`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreatePaymentMethod
         * @request POST:/stores/{id}/payment_methods
         * @secure
         * @response `200` `PaymentMethodDto`
         * @response `404` `void`
         */
        createPaymentMethod: (
            id: string,
            data: CreatePaymentMethodDto,
            params: RequestParams = {},
        ) =>
            this.request<PaymentMethodDto, void>({
                path: `/stores/${id}/payment_methods`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetStorePaymentMethods
         * @request GET:/stores/{id}/payment_methods
         * @secure
         * @response `200` `(PaymentMethodDto)[]`
         */
        getStorePaymentMethods: (id: string, params: RequestParams = {}) =>
            this.request<PaymentMethodDto[], any>({
                path: `/stores/${id}/payment_methods`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetStoreSubscriptions
 * @request GET:/stores/{id}/subscriptions
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreSubscriptionDto)[],

})` Successfully received storesubscriptiondto list
 * @response `404` `void`
 */
        getStoreSubscriptions: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreSubscriptionDto[];
                },
                void
            >({
                path: `/stores/${id}/subscriptions`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name GetWebsites
         * @request GET:/stores/{id}/websites
         * @secure
         * @response `200` `(WebsiteDto)[]`
         */
        getWebsites: (id: string, params: RequestParams = {}) =>
            this.request<WebsiteDto[], any>({
                path: `/stores/${id}/websites`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateWebsite
         * @request POST:/stores/{id}/websites
         * @secure
         * @response `200` `WebsiteDto`
         * @response `404` `void`
         */
        createWebsite: (id: string, data: CreateWebsiteDto, params: RequestParams = {}) =>
            this.request<WebsiteDto, void>({
                path: `/stores/${id}/websites`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetStoreServiceAccounts
 * @request GET:/stores/{id}/service-accounts
 * @secure
 * @response `200` `(PageDto & {
    data?: (ServiceAccountDto)[],

})` Successfully received serviceaccountdto list
 */
        getStoreServiceAccounts: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ServiceAccountDto[];
                },
                any
            >({
                path: `/stores/${id}/service-accounts`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateServiceAccount
         * @request POST:/stores/{id}/service-accounts
         * @secure
         * @response `200` `ServiceAccountDto`
         * @response `400` `ErrorsDto`
         */
        createServiceAccount: (id: string, params: RequestParams = {}) =>
            this.request<ServiceAccountDto, ErrorsDto>({
                path: `/stores/${id}/service-accounts`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name CreateInvitation
         * @request POST:/stores/{id}/invitations
         * @secure
         * @response `200` `InvitationDto`
         * @response `404` `void`
         */
        createInvitation: (id: string, data: CreateInvitationDto, params: RequestParams = {}) =>
            this.request<InvitationDto, void>({
                path: `/stores/${id}/invitations`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name DeleteStoreUser
         * @request DELETE:/stores/{id}/user/{userId}
         * @secure
         * @response `200` `void`
         * @response `404` `void`
         */
        deleteStoreUser: (id: string, userId: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/stores/${id}/user/${userId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetStoreCustomers
 * @request GET:/stores/{id}/customers
 * @secure
 * @response `200` `(PageDto & {
    data?: (CustomerDto)[],

})` Successfully received customerdto list
 */
        getStoreCustomers: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: CustomerDto[];
                },
                any
            >({
                path: `/stores/${id}/customers`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name UpdateSubscriptionsContract
         * @request POST:/stores/{id}/update-subscriptions-contract
         * @secure
         * @response `200` `ContractChangeResultResponseDto`
         */
        updateSubscriptionsContract: (
            id: string,
            data: UpdateSubscriptionsContractDto,
            params: RequestParams = {},
        ) =>
            this.request<ContractChangeResultResponseDto, any>({
                path: `/stores/${id}/update-subscriptions-contract`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name UnlinkParent
         * @request PATCH:/stores/{id}/unlink-parent
         * @secure
         * @response `200` `void`
         */
        unlinkParent: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/stores/${id}/unlink-parent`,
                method: 'PATCH',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name LinkParent
         * @request PATCH:/stores/{id}/link-parent
         * @secure
         * @response `200` `void`
         */
        linkParent: (
            id: string,
            query: {
                parentId: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/stores/${id}/link-parent`,
                method: 'PATCH',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name DuplicateStoreConfig
         * @request PATCH:/stores/{id}/duplicate-config
         * @secure
         * @response `200` `void`
         */
        duplicateStoreConfig: (
            id: string,
            data: DuplicateStoreConfigDto,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/stores/${id}/duplicate-config`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stores
         * @name ToggleGenericCustomerSpace
         * @request PATCH:/stores/{id}/toggle-generic-customer-space
         * @secure
         * @response `204` `void`
         */
        toggleGenericCustomerSpace: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/stores/${id}/toggle-generic-customer-space`,
                method: 'PATCH',
                secure: true,
                ...params,
            }),

        /**
         * @description Update custom transaction by batch
         *
         * @tags stores
         * @name UpdateCustomTransactionByBatch
         * @request PATCH:/stores/{id}/transactions/custom
         * @secure
         * @response `200` `UpdateTransactionByBatchResponseDto`
         */
        updateCustomTransactionByBatch: (
            id: string,
            data: UpdateTransactionByBatchDto,
            params: RequestParams = {},
        ) =>
            this.request<UpdateTransactionByBatchResponseDto, any>({
                path: `/stores/${id}/transactions/custom`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags stores
 * @name GetStoreClaims
 * @request GET:/stores/{id}/claims
 * @secure
 * @response `200` `(PageDto & {
    data?: (StoreClaimDto)[],

})` Successfully received storeclaimdto list
 */
        getStoreClaims: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                associated?: boolean;
                excludeClaimId?: string;
                status?: ClaimStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreClaimDto[];
                },
                any
            >({
                path: `/stores/${id}/claims`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),
    };
    contracts = {
        /**
         * No description
         *
         * @tags contracts
         * @name GetContract
         * @request GET:/contracts/{id}
         * @response `200` `ContractDto`
         * @response `404` `void`
         */
        getContract: (id: string, params: RequestParams = {}) =>
            this.request<ContractDto, void>({
                path: `/contracts/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name UpdateContract
         * @request PATCH:/contracts/{id}
         * @response `200` `ContractDto`
         * @response `400` `ErrorsDto`
         */
        updateContract: (id: string, data: UpdateContractDto, params: RequestParams = {}) =>
            this.request<ContractDto, ErrorsDto>({
                path: `/contracts/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name DeleteContract
         * @request DELETE:/contracts/{id}
         * @response `200` `void`
         */
        deleteContract: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/contracts/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name GetContractById
         * @request GET:/contracts/contract/{id}
         * @response `200` `ContractDto`
         * @response `404` `void`
         */
        getContractById: (id: string, params: RequestParams = {}) =>
            this.request<ContractDto, void>({
                path: `/contracts/contract/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name SubscribeV1
         * @request POST:/contracts/{id}/subscriptions
         * @response `200` `CreateSubscriptionResponseDto`
         * @response `400` `ErrorsDto`
         */
        subscribeV1: (id: string, data: CreateSubscriptionV1Dto, params: RequestParams = {}) =>
            this.request<CreateSubscriptionResponseDto, ErrorsDto>({
                path: `/contracts/${id}/subscriptions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name GetContractSubscriptionByExternalId
         * @request GET:/contracts/{contractId}/subscriptions/external-id/{externalId}
         * @response `200` `SubscriptionDto`
         * @response `400` `ErrorsDto`
         */
        getContractSubscriptionByExternalId: (
            contractId: string,
            externalId: string,
            params: RequestParams = {},
        ) =>
            this.request<SubscriptionDto, ErrorsDto>({
                path: `/contracts/${contractId}/subscriptions/external-id/${externalId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags contracts
 * @name GetPaginatedSubscriptionsToChangeContract
 * @request GET:/contracts/{contractId}/subscriptions
 * @response `200` `(PageDto & {
    data?: (ContractSubscriptionDto)[],

})` Successfully received contractsubscriptiondto list
 * @response `400` `ErrorsDto`
 */
        getPaginatedSubscriptionsToChangeContract: (
            contractId: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @format date-time */
                from?: string;
                /** @format date-time */
                to?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ContractSubscriptionDto[];
                },
                ErrorsDto
            >({
                path: `/contracts/${contractId}/subscriptions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name SubscribeBatched
         * @request POST:/contracts/{id}/subscriptions/batch
         * @response `200` `CreateSubscriptionResponseDto`
         * @response `400` `ErrorsDto`
         */
        subscribeBatched: (id: string, data: string[], params: RequestParams = {}) =>
            this.request<CreateSubscriptionResponseDto, ErrorsDto>({
                path: `/contracts/${id}/subscriptions/batch`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name UpdateContractMailTemplates
         * @request PATCH:/contracts/{id}/mail-templates
         * @response `200` `ContractDto`
         * @response `400` `ErrorsDto`
         */
        updateContractMailTemplates: (
            id: string,
            data: UpdateContractMailTemplatesDto,
            params: RequestParams = {},
        ) =>
            this.request<ContractDto, ErrorsDto>({
                path: `/contracts/${id}/mail-templates`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name GetContractDocumentTemplates
         * @request GET:/contracts/{id}/document-templates
         * @response `200` `(ContractDocumentTemplateDto)[]`
         * @response `400` `ErrorsDto`
         */
        getContractDocumentTemplates: (id: string, params: RequestParams = {}) =>
            this.request<ContractDocumentTemplateDto[], ErrorsDto>({
                path: `/contracts/${id}/document-templates`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name AddContractDocumentTemplate
         * @request POST:/contracts/{id}/document-templates
         * @response `200` `ContractDocumentTemplateDto`
         * @response `400` `ErrorsDto`
         */
        addContractDocumentTemplate: (
            id: string,
            data: SetContractDocumentTemplateDto,
            params: RequestParams = {},
        ) =>
            this.request<ContractDocumentTemplateDto, ErrorsDto>({
                path: `/contracts/${id}/document-templates`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags contracts
         * @name DeleteContractDocumentTemplate
         * @request DELETE:/contracts/{id}/document-templates/{contractDocumentTemplateId}
         * @response `200` `void`
         */
        deleteContractDocumentTemplate: (
            id: string,
            contractDocumentTemplateId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/contracts/${id}/document-templates/${contractDocumentTemplateId}`,
                method: 'DELETE',
                ...params,
            }),
    };
    v2 = {
        /**
         * No description
         *
         * @tags contracts
         * @name Subscribe
         * @request POST:/v2/contracts/{id}/subscriptions
         * @response `200` `CreateSubscriptionResponseDto`
         * @response `400` `ErrorsDto`
         */
        subscribe: (id: string, data: CreateSubscriptionDto, params: RequestParams = {}) =>
            this.request<CreateSubscriptionResponseDto, ErrorsDto>({
                path: `/v2/contracts/${id}/subscriptions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pricings
         * @name ComputeSubscriptionPrice
         * @request POST:/v2/pricings/{contractId}/price
         * @response `200` `PriceProductDto`
         * @response `404` `void`
         */
        computeSubscriptionPrice: (
            contractId: string,
            data: GetSubscriptionPriceDto,
            params: RequestParams = {},
        ) =>
            this.request<PriceProductDto, void>({
                path: `/v2/pricings/${contractId}/price`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pricings
         * @name ComputeBatchedSubscriptionPrice
         * @request POST:/v2/pricings/price-batching
         * @response `200` `(SubscriptionPriceResponseDto)[]`
         * @response `404` `void`
         */
        computeBatchedSubscriptionPrice: (data: string[], params: RequestParams = {}) =>
            this.request<SubscriptionPriceResponseDto[], void>({
                path: `/v2/pricings/price-batching`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    files = {
        /**
         * No description
         *
         * @tags files
         * @name GetFilePresignedUri
         * @request GET:/files/{id}/signedUri
         * @response `200` `string`
         */
        getFilePresignedUri: (id: string, params: RequestParams = {}) =>
            this.request<string, any>({
                path: `/files/${id}/signedUri`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags files
         * @name GetFileBase64
         * @request GET:/files/{id}/download
         * @response `200` `string`
         */
        getFileBase64: (id: string, params: RequestParams = {}) =>
            this.request<string, any>({
                path: `/files/${id}/download`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    subscriptionProcedures = {
        /**
         * No description
         *
         * @tags subscription-procedures
         * @name FetchSubscriptionProcedureUpdates
         * @request GET:/subscription-procedures/{id}/updates
         * @response `200` `FetchSubscriptionProcedureUpdateResponseDto`
         */
        fetchSubscriptionProcedureUpdates: (id: string, params: RequestParams = {}) =>
            this.request<FetchSubscriptionProcedureUpdateResponseDto, any>({
                path: `/subscription-procedures/${id}/updates`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    subscriptions = {
        /**
 * No description
 *
 * @tags subscriptions
 * @name GetPaginatedSubscriptions
 * @request GET:/subscriptions
 * @response `200` `(PageDto & {
    data?: (PaginatedSubscriptionDto)[],

})` Successfully received paginatedsubscriptiondto list
 * @response `404` `void`
 */
        getPaginatedSubscriptions: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: PaginatedSubscriptionDto[];
                },
                void
            >({
                path: `/subscriptions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetSubscriptionById
         * @request GET:/subscriptions/{id}
         * @response `200` `SubscriptionDto`
         * @response `400` `ErrorsDto`
         */
        getSubscriptionById: (id: string, params: RequestParams = {}) =>
            this.request<SubscriptionDto, ErrorsDto>({
                path: `/subscriptions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name AmendSubscription
         * @request PATCH:/subscriptions/{id}
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        amendSubscription: (
            id: string,
            data: UpdateServiceFieldsValuesDto,
            params: RequestParams = {},
        ) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetPaymentMean
         * @request GET:/subscriptions/{id}/payment-mean
         * @response `200` `PaymentMeanDto`
         * @response `204` `void`
         * @response `400` `ErrorsDto`
         */
        getPaymentMean: (id: string, params: RequestParams = {}) =>
            this.request<PaymentMeanDto, ErrorsDto>({
                path: `/subscriptions/${id}/payment-mean`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetByReference
         * @request GET:/subscriptions/reference/{reference}
         * @response `200` `SubscriptionDto`
         * @response `400` `ErrorsDto`
         */
        getByReference: (reference: string, params: RequestParams = {}) =>
            this.request<SubscriptionDto, ErrorsDto>({
                path: `/subscriptions/reference/${reference}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name VoidSubscription
         * @request POST:/subscriptions/{id}/void
         * @response `200` `SubscriptionVoidDto`
         * @response `400` `ErrorsDto`
         */
        voidSubscription: (id: string, data: VoidSubscriptionDto, params: RequestParams = {}) =>
            this.request<SubscriptionVoidDto, ErrorsDto>({
                path: `/subscriptions/${id}/void`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name RefundSubscription
         * @request POST:/subscriptions/{id}/refund
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        refundSubscription: (id: string, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}/refund`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name RegenerateMembershipAgreement
         * @request POST:/subscriptions/{id}/regenerate
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        regenerateMembershipAgreement: (id: string, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}/regenerate`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name RegenerateAndValidateMembershipAgreement
         * @request POST:/subscriptions/{id}/regenerate-validation
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        regenerateAndValidateMembershipAgreement: (
            id: string,
            data: RegenerateCertificateDto,
            params: RequestParams = {},
        ) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}/regenerate-validation`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name SendDocumentSubscription
         * @request POST:/subscriptions/{id}/send-document
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        sendDocumentSubscription: (id: string, data: FileDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}/send-document`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetProceduresBySubscriptionId
         * @request GET:/subscriptions/{id}/procedures
         * @response `200` `(SubscriptionProcedureDto)[]`
         * @response `400` `ErrorsDto`
         */
        getProceduresBySubscriptionId: (id: string, params: RequestParams = {}) =>
            this.request<SubscriptionProcedureDto[], ErrorsDto>({
                path: `/subscriptions/${id}/procedures`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name UpdateTacitRenewal
         * @request PATCH:/subscriptions/{id}/tacit-renewal
         * @response `204` `void`
         * @response `400` `ErrorsDto`
         */
        updateTacitRenewal: (id: string, data: UpdateTacitRenewalDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/subscriptions/${id}/tacit-renewal`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetSubscriptionUsedPaymentMeans
         * @request GET:/subscriptions/{id}/used-payment-means
         * @response `200` `(SubscriptionUsedPaymentMeanDto)[]`
         * @response `400` `ErrorsDto`
         */
        getSubscriptionUsedPaymentMeans: (id: string, params: RequestParams = {}) =>
            this.request<SubscriptionUsedPaymentMeanDto[], ErrorsDto>({
                path: `/subscriptions/${id}/used-payment-means`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags subscriptions
         * @name GetSubscriptionBillingTimeline
         * @request GET:/subscriptions/{id}/billing-timeline
         * @response `200` `(SubscriptionBillingTimelineDto)[]`
         * @response `400` `ErrorsDto`
         */
        getSubscriptionBillingTimeline: (id: string, params: RequestParams = {}) =>
            this.request<SubscriptionBillingTimelineDto[], ErrorsDto>({
                path: `/subscriptions/${id}/billing-timeline`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    pricings = {
        /**
         * No description
         *
         * @tags pricings
         * @name ComputeSubscriptionPriceV1
         * @request POST:/pricings/{contractId}/price
         * @response `200` `PriceProductDto`
         * @response `404` `void`
         */
        computeSubscriptionPriceV1: (
            contractId: string,
            data: GetSubscriptionPriceDtoV1,
            params: RequestParams = {},
        ) =>
            this.request<PriceProductDto, void>({
                path: `/pricings/${contractId}/price`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pricings
         * @name ComputePriceBySubscriptionId
         * @request POST:/pricings/subscriptions/{subscriptionId}/price
         * @response `200` `PriceProductDto`
         * @response `404` `void`
         */
        computePriceBySubscriptionId: (
            subscriptionId: string,
            data: GetPriceBySubscriptionIdDto,
            params: RequestParams = {},
        ) =>
            this.request<PriceProductDto, void>({
                path: `/pricings/subscriptions/${subscriptionId}/price`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pricings
         * @name ComputeBatchedSubscriptionPriceV1
         * @request POST:/pricings/price-batching
         * @response `200` `(SubscriptionPriceResponseDto)[]`
         * @response `404` `void`
         */
        computeBatchedSubscriptionPriceV1: (data: string[], params: RequestParams = {}) =>
            this.request<SubscriptionPriceResponseDto[], void>({
                path: `/pricings/price-batching`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    mails = {
        /**
         * No description
         *
         * @tags mails
         * @name GetMailTemplate
         * @request GET:/mails/templates/{id}
         * @response `200` `MailTemplateDto`
         * @response `404` `void`
         */
        getMailTemplate: (id: string, params: RequestParams = {}) =>
            this.request<MailTemplateDto, void>({
                path: `/mails/templates/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mails
         * @name UpdateMailTemplate
         * @request PATCH:/mails/templates/{id}
         * @response `200` `MailTemplateDto`
         * @response `404` `void`
         */
        updateMailTemplate: (id: string, data: UpdateMailTemplateDto, params: RequestParams = {}) =>
            this.request<MailTemplateDto, void>({
                path: `/mails/templates/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mails
         * @name CreateMailTemplate
         * @request POST:/mails/templates
         * @response `200` `MailTemplateDto`
         */
        createMailTemplate: (data: CreateMailTemplateDto, params: RequestParams = {}) =>
            this.request<MailTemplateDto, any>({
                path: `/mails/templates`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags mails
 * @name GetPaginatedMailTemplates
 * @request GET:/mails/templates
 * @response `200` `(PageDto & {
    data?: (MailTemplateDto)[],

})` Successfully received mailtemplatedto list
 */
        getPaginatedMailTemplates: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: MailTemplateDto[];
                },
                any
            >({
                path: `/mails/templates`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mails
         * @name GetMailTemplateByType
         * @request GET:/mails/templates/by-type/{type}
         * @response `200` `(MailTemplateDto)[]`
         * @response `404` `void`
         */
        getMailTemplateByType: (type: string, params: RequestParams = {}) =>
            this.request<MailTemplateDto[], void>({
                path: `/mails/templates/by-type/${type}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    transactions = {
        /**
 * No description
 *
 * @tags transactions
 * @name GetTransactionsWithoutStoreInvoice
 * @request GET:/transactions
 * @response `200` `(PageDto & {
    data?: (StoreInvoiceTransactionDto)[],

})` Successfully received storeinvoicetransactiondto list
 */
        getTransactionsWithoutStoreInvoice: (
            query: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                storeId: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreInvoiceTransactionDto[];
                },
                any
            >({
                path: `/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    paymentMeanProcedures = {
        /**
         * No description
         *
         * @tags payment-mean-procedures
         * @name FetchPaymentMeanProcedureUpdates
         * @request GET:/payment-mean-procedures/{id}/updates
         * @response `200` `FetchPaymentMeanProcedureUpdateResponseDto`
         */
        fetchPaymentMeanProcedureUpdates: (id: string, params: RequestParams = {}) =>
            this.request<FetchPaymentMeanProcedureUpdateResponseDto, any>({
                path: `/payment-mean-procedures/${id}/updates`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    paymentMeans = {
        /**
         * No description
         *
         * @tags payment-means
         * @name UpdatePaymentMean
         * @request PATCH:/payment-means/{id}
         * @response `200` `PaymentMeanDto`
         * @response `404` `void`
         */
        updatePaymentMean: (id: string, data: UpdatePaymentMeanDto, params: RequestParams = {}) =>
            this.request<PaymentMeanDto, void>({
                path: `/payment-means/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags payment-means
         * @name AssignPaymentMeanToSubscriptions
         * @request PATCH:/payment-means/{id}/subscriptions
         * @response `200` `CustomerPaymentMeanDto`
         * @response `404` `void`
         */
        assignPaymentMeanToSubscriptions: (
            id: string,
            data: AssignPaymentMeanToSubscriptionsDto,
            params: RequestParams = {},
        ) =>
            this.request<CustomerPaymentMeanDto, void>({
                path: `/payment-means/${id}/subscriptions`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    customers = {
        /**
         * No description
         *
         * @tags customers
         * @name GetAuthenticatedCustomer
         * @request GET:/customers/me
         * @secure
         * @response `200` `CustomerDto`
         */
        getAuthenticatedCustomer: (params: RequestParams = {}) =>
            this.request<CustomerDto, any>({
                path: `/customers/me`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name GetCustomerStores
         * @request GET:/customers/me/stores
         * @secure
         * @response `200` `(CustomerStoreDto)[]`
         */
        getCustomerStores: (params: RequestParams = {}) =>
            this.request<CustomerStoreDto[], any>({
                path: `/customers/me/stores`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name GetCustomerById
         * @request GET:/customers/{id}
         * @secure
         * @response `200` `CustomerDto`
         */
        getCustomerById: (id: string, params: RequestParams = {}) =>
            this.request<CustomerDto, any>({
                path: `/customers/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name UpdateCustomer
         * @request PATCH:/customers/{id}
         * @secure
         * @response `200` `CustomerDto`
         * @response `400` `ErrorsDto`
         */
        updateCustomer: (id: string, data: UpdateCustomerDto, params: RequestParams = {}) =>
            this.request<CustomerDto, ErrorsDto>({
                path: `/customers/${id}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags customers
 * @name GetCustomerSubscriptions
 * @request GET:/customers/{id}/subscriptions
 * @secure
 * @response `200` `(PageDto & {
    data?: (CustomerPaginatedSubscriptionDto)[],

})` Successfully received customerpaginatedsubscriptiondto list
 */
        getCustomerSubscriptions: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                subscriptionStatus?: SubscriptionStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: CustomerPaginatedSubscriptionDto[];
                },
                any
            >({
                path: `/customers/${id}/subscriptions`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name GetCustomerSubscriptionsLastTransaction
         * @request GET:/customers/{id}/subscriptions-last-transaction
         * @secure
         * @response `200` `(CustomerSubscriptionsLastTransactionDto)[]`
         */
        getCustomerSubscriptionsLastTransaction: (
            id: string,
            query?: {
                status?: TransactionStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<CustomerSubscriptionsLastTransactionDto[], any>({
                path: `/customers/${id}/subscriptions-last-transaction`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags customers
 * @name GetPaginatedCustomerTransactions
 * @request GET:/customers/{id}/transactions
 * @secure
 * @response `200` `(PageDto & {
    data?: (CustomerPaginatedTransactionDto)[],

})` Successfully received customerpaginatedtransactiondto list
 */
        getPaginatedCustomerTransactions: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                status?: TransactionStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: CustomerPaginatedTransactionDto[];
                },
                any
            >({
                path: `/customers/${id}/transactions`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name GetCustomerPaymentMeans
         * @request GET:/customers/{id}/payment-means
         * @secure
         * @response `200` `(CustomerPaymentMeanDto)[]`
         */
        getCustomerPaymentMeans: (
            id: string,
            query?: {
                paymentMeanStatus?: PaymentMeanStatus[];
                paymentMeanTypes?: PaymentMeanType[];
            },
            params: RequestParams = {},
        ) =>
            this.request<CustomerPaymentMeanDto[], any>({
                path: `/customers/${id}/payment-means`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name AddCustomerPaymentMean
         * @request POST:/customers/{id}/payment-means
         * @secure
         * @response `200` `CustomerPaymentMeanDto`
         */
        addCustomerPaymentMean: (
            id: string,
            data: CreatePaymentMeanDto,
            params: RequestParams = {},
        ) =>
            this.request<CustomerPaymentMeanDto, any>({
                path: `/customers/${id}/payment-means`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name AuthCustomer
         * @request POST:/customers/auth
         * @secure
         * @response `200` `CustomerAuthToken`
         * @response `400` `ErrorsDto`
         */
        authCustomer: (data: CreateCustomerAuthTokenDto, params: RequestParams = {}) =>
            this.request<CustomerAuthToken, ErrorsDto>({
                path: `/customers/auth`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags customers
         * @name SendMultiCustomersMagicLink
         * @request POST:/customers/send-multi-customers-magic-link
         * @secure
         * @response `200` `void`
         */
        sendMultiCustomersMagicLink: (
            data: CreateMultiCustomersMagicLinkDto,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/customers/send-multi-customers-magic-link`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
    };
    beneficiaries = {
        /**
         * No description
         *
         * @tags beneficiaries
         * @name GetBeneficiaryById
         * @request GET:/beneficiaries/{id}
         * @response `200` `BeneficiaryDto`
         */
        getBeneficiaryById: (id: string, params: RequestParams = {}) =>
            this.request<BeneficiaryDto, any>({
                path: `/beneficiaries/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags beneficiaries
         * @name UpdateBeneficiary
         * @request PATCH:/beneficiaries/{id}
         * @response `200` `BeneficiaryDto`
         * @response `400` `ErrorsDto`
         */
        updateBeneficiary: (id: string, data: UpdateBeneficiaryDto, params: RequestParams = {}) =>
            this.request<BeneficiaryDto, ErrorsDto>({
                path: `/beneficiaries/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    emailVerificationCodes = {
        /**
         * No description
         *
         * @tags email-verification-codes
         * @name CreateEmailVerificationCode
         * @request POST:/email-verification-codes/{contractId}
         * @response `200` `EmailVerificationCodeDto`
         * @response `404` `void`
         */
        createEmailVerificationCode: (
            contractId: string,
            data: CreateEmailVerificationCodeDto,
            params: RequestParams = {},
        ) =>
            this.request<EmailVerificationCodeDto, void>({
                path: `/email-verification-codes/${contractId}`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    payment = {
        /**
         * No description
         *
         * @tags payment/stripe
         * @name StripeCreateSetupIntent
         * @request POST:/payment/stripe/setup-intent
         * @response `200` `StripeCreateSetupIntentResponseDto`
         */
        stripeCreateSetupIntent: (params: RequestParams = {}) =>
            this.request<StripeCreateSetupIntentResponseDto, any>({
                path: `/payment/stripe/setup-intent`,
                method: 'POST',
                format: 'json',
                ...params,
            }),
    };
    dealers = {
        /**
         * No description
         *
         * @tags dealers
         * @name CreateDealer
         * @request POST:/dealers
         * @response `400` `ErrorsDto`
         */
        createDealer: (data: CreateDealerDto, params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/dealers`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name RegisterDealer
         * @request POST:/dealers/register
         * @response `200` `CreateDealerDto`
         * @response `400` `ErrorsDto`
         */
        registerDealer: (data: CreateDealerDto, params: RequestParams = {}) =>
            this.request<CreateDealerDto, ErrorsDto>({
                path: `/dealers/register`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name GetAuthenticatedDealer
         * @request GET:/dealers/me
         * @response `200` `DealerDto`
         */
        getAuthenticatedDealer: (params: RequestParams = {}) =>
            this.request<DealerDto, any>({
                path: `/dealers/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name GetDealerStores
         * @request GET:/dealers/{id}/stores
         * @response `200` `(StoreDto)[]`
         */
        getDealerStores: (id: string, params: RequestParams = {}) =>
            this.request<StoreDto[], any>({
                path: `/dealers/${id}/stores`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name GetDealerStore
         * @request GET:/dealers/{id}/stores/{storeId}
         * @response `200` `StoreDto`
         */
        getDealerStore: (id: string, storeId: string, params: RequestParams = {}) =>
            this.request<StoreDto, any>({
                path: `/dealers/${id}/stores/${storeId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags dealers
 * @name GetDealerPaginatedStores
 * @request GET:/dealers/{id}/paginated-stores
 * @response `200` `(PageDto & {
    data?: (StoreDto)[],

})` Successfully received storedto list
 */
        getDealerPaginatedStores: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreDto[];
                },
                any
            >({
                path: `/dealers/${id}/paginated-stores`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name UpdateDealerRole
         * @request PATCH:/dealers/{id}/role
         * @response `200` `void`
         */
        updateDealerRole: (id: string, data: UpdateDealerRoleDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/dealers/${id}/role`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name UpdateDealerInfos
         * @request PATCH:/dealers/{id}
         * @response `200` `void`
         */
        updateDealerInfos: (id: string, data: UpdateDealerDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/dealers/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dealers
         * @name UnlockDealer
         * @request POST:/dealers/{id}/unlock
         * @response `204` `void`
         * @response `400` `ErrorsDto`
         */
        unlockDealer: (id: string, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/dealers/${id}/unlock`,
                method: 'POST',
                ...params,
            }),
    };
    paymentMethods = {
        /**
         * No description
         *
         * @tags payment-methods
         * @name UpdatePaymentMethod
         * @request PATCH:/payment-methods/{id}
         * @response `200` `PaymentMethodDto`
         * @response `404` `void`
         */
        updatePaymentMethod: (
            id: string,
            data: UpdatePaymentMethodDto,
            params: RequestParams = {},
        ) =>
            this.request<PaymentMethodDto, void>({
                path: `/payment-methods/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags payment-methods
         * @name DeletePaymentMethod
         * @request DELETE:/payment-methods/{id}
         * @response `200` `void`
         * @response `404` `void`
         */
        deletePaymentMethod: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/payment-methods/${id}`,
                method: 'DELETE',
                ...params,
            }),
    };
    salesChannels = {
        /**
         * No description
         *
         * @tags sales-channels
         * @name GetSalesChannel
         * @request GET:/sales-channels/{id}
         * @response `200` `SalesChannelsWithPaymentMethodDto`
         * @response `404` `void`
         */
        getSalesChannel: (id: string, params: RequestParams = {}) =>
            this.request<SalesChannelsWithPaymentMethodDto, void>({
                path: `/sales-channels/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sales-channels
         * @name UpdateSalesChannel
         * @request PATCH:/sales-channels/{id}
         * @response `200` `SalesChannelsDto`
         * @response `404` `void`
         */
        updateSalesChannel: (
            id: string,
            data: UpdateSalesChannelsDto,
            params: RequestParams = {},
        ) =>
            this.request<SalesChannelsDto, void>({
                path: `/sales-channels/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sales-channels
         * @name DeleteSalesChannel
         * @request DELETE:/sales-channels/{id}
         * @response `200` `void`
         * @response `404` `void`
         */
        deleteSalesChannel: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/sales-channels/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sales-channels
         * @name GetSalesChannelContracts
         * @request GET:/sales-channels/{id}/contracts
         * @response `200` `(SalesChannelContractsItemDto)[]`
         * @response `404` `void`
         */
        getSalesChannelContracts: (id: string, params: RequestParams = {}) =>
            this.request<SalesChannelContractsItemDto[], void>({
                path: `/sales-channels/${id}/contracts`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sales-channels
         * @name GetSalesChannelPaymentMethods
         * @request GET:/sales-channels/{id}/payment-methods
         * @response `200` `(PaymentMethodDto)[]`
         * @response `404` `void`
         */
        getSalesChannelPaymentMethods: (id: string, params: RequestParams = {}) =>
            this.request<PaymentMethodDto[], void>({
                path: `/sales-channels/${id}/payment-methods`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sales-channels
         * @name GetSubscribeFields
         * @request GET:/sales-channels/v1/{salesChannelId}/contracts/{contractId}/subscribe-fields
         * @response `200` `(SubscribeFieldsDto)[]`
         * @response `404` `void`
         */
        getSubscribeFields: (
            salesChannelId: string,
            contractId: string,
            params: RequestParams = {},
        ) =>
            this.request<SubscribeFieldsDto[], void>({
                path: `/sales-channels/v1/${salesChannelId}/contracts/${contractId}/subscribe-fields`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    websites = {
        /**
         * No description
         *
         * @tags websites
         * @name GetWebsiteById
         * @request GET:/websites/{id}
         * @response `200` `WebsiteDto` Website found
         * @response `404` `void` Website not found
         */
        getWebsiteById: (id: string, params: RequestParams = {}) =>
            this.request<WebsiteDto, void>({
                path: `/websites/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags websites
         * @name UpdateById
         * @request PATCH:/websites/{id}
         * @response `200` `WebsiteDto` Website updated
         * @response `404` `void` Website not found
         */
        updateById: (id: string, data: UpdateWebsiteDto, params: RequestParams = {}) =>
            this.request<WebsiteDto, void>({
                path: `/websites/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags websites
         * @name DeleteById
         * @request DELETE:/websites/{id}
         * @response `204` `void`
         */
        deleteById: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/websites/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags websites
         * @name CreateMagicLink
         * @request POST:/websites/{id}/magic-link
         * @response `204` `void`
         */
        createMagicLink: (id: string, data: CreateMagicLinkDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/websites/${id}/magic-link`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    customerInvoices = {
        /**
         * No description
         *
         * @tags customer-invoices
         * @name GenerateCustomerInvoicePdf
         * @request GET:/customer-invoices/transaction/{id}/generate/pdf
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        generateCustomerInvoicePdf: (id: string, params: RequestParams = {}) =>
            this.request<string, ErrorsDto>({
                path: `/customer-invoices/transaction/${id}/generate/pdf`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    storeInvoices = {
        /**
 * No description
 *
 * @tags store-invoices
 * @name GetAllStoresInvoices
 * @request GET:/store-invoices
 * @response `200` `(PageDto & {
    data?: (StoreInvoiceDto)[],

})` Successfully received storeinvoicedto list
 */
        getAllStoresInvoices: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @format date-time */
                from?: string;
                /** @format date-time */
                to?: string;
                status?: string[];
                isSelfCharged?: boolean;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreInvoiceDto[];
                },
                any
            >({
                path: `/store-invoices`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name DeleteStoreInvoices
         * @request DELETE:/store-invoices
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         * @response `404` `void`
         */
        deleteStoreInvoices: (
            query: {
                storeInvoiceIds: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<void, ErrorsDto | void>({
                path: `/store-invoices`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GetStoreInvoiceById
         * @request GET:/store-invoices/{id}
         * @response `200` `StoreInvoiceDto`
         * @response `404` `void`
         */
        getStoreInvoiceById: (id: string, params: RequestParams = {}) =>
            this.request<StoreInvoiceDto, void>({
                path: `/store-invoices/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags store-invoices
 * @name GetStoreInvoiceSubscriptions
 * @request GET:/store-invoices/{id}/subscriptions
 * @response `200` `(PageDto & {
    data?: (SubscriptionDto)[],

})` Successfully received subscriptiondto list
 */
        getStoreInvoiceSubscriptions: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: SubscriptionDto[];
                },
                any
            >({
                path: `/store-invoices/${id}/subscriptions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags store-invoices
 * @name GetStoreInvoiceTransactions
 * @request GET:/store-invoices/{id}/transactions
 * @response `200` `(PageDto & {
    data?: (StoreInvoiceTransactionDto)[],

})` Successfully received storeinvoicetransactiondto list
 */
        getStoreInvoiceTransactions: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreInvoiceTransactionDto[];
                },
                any
            >({
                path: `/store-invoices/${id}/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GetSubscriptionInvoicesByStoreInvoiceId
         * @request GET:/store-invoices/{id}/subscription-invoices
         * @response `200` `(SubscriptionInvoiceDto)[]`
         * @response `404` `void`
         */
        getSubscriptionInvoicesByStoreInvoiceId: (id: string, params: RequestParams = {}) =>
            this.request<SubscriptionInvoiceDto[], void>({
                path: `/store-invoices/${id}/subscription-invoices`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name UpdateStoreInvoiceDetails
         * @request PATCH:/store-invoices/{id}/details
         * @response `204` `void`
         */
        updateStoreInvoiceDetails: (
            id: string,
            data: UpdateStoreInvoiceDetailsDto,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/store-invoices/${id}/details`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name UpdateStoreInvoiceStatus
         * @request PATCH:/store-invoices/{id}/status
         * @response `200` `void`
         * @response `404` `void`
         */
        updateStoreInvoiceStatus: (
            id: string,
            data: UpdateStoreInvoiceStatusDto,
            params: RequestParams = {},
        ) =>
            this.request<void, void>({
                path: `/store-invoices/${id}/status`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name UpdateMultipleStoreStoreInvoicesStatus
         * @request PATCH:/store-invoices/status
         * @response `200` `void`
         * @response `404` `void`
         */
        updateMultipleStoreStoreInvoicesStatus: (
            data: UpdateMultipleStoreInvoiceStatusDto,
            params: RequestParams = {},
        ) =>
            this.request<void, void>({
                path: `/store-invoices/status`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateStoreInvoicePdf
         * @request GET:/store-invoices/{id}/generate/pdf
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        generateStoreInvoicePdf: (id: string, params: RequestParams = {}) =>
            this.request<string, ErrorsDto>({
                path: `/store-invoices/${id}/generate/pdf`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateStoreInvoiceCsv
         * @request GET:/store-invoices/{id}/generate/csv
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        generateStoreInvoiceCsv: (id: string, params: RequestParams = {}) =>
            this.request<string, ErrorsDto>({
                path: `/store-invoices/${id}/generate/csv`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateStoreInvoiceXlsx
         * @request GET:/store-invoices/{id}/generate/xlsx
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        generateStoreInvoiceXlsx: (id: string, params: RequestParams = {}) =>
            this.request<string, ErrorsDto>({
                path: `/store-invoices/${id}/generate/xlsx`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateAllStoreInvoicePdf
         * @request GET:/store-invoices/generate/all/pdf
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         * @response `404` `void`
         */
        generateAllStoreInvoicePdf: (
            query: {
                storeInvoiceIds: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto | void>({
                path: `/store-invoices/generate/all/pdf`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name SendSelectedInvoicesByEmail
         * @request POST:/store-invoices/send/selected
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        sendSelectedInvoicesByEmail: (
            data: SendSelectedInvoicesByMailDto,
            params: RequestParams = {},
        ) =>
            this.request<void, ErrorsDto>({
                path: `/store-invoices/send/selected`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateAllStoreInvoiceCsv
         * @request GET:/store-invoices/generate/all/csv
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         * @response `404` `void`
         */
        generateAllStoreInvoiceCsv: (
            query: {
                storeInvoiceIds: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto | void>({
                path: `/store-invoices/generate/all/csv`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateAllStoreInvoiceXlsx
         * @request GET:/store-invoices/generate/all/xlsx
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         * @response `404` `void`
         */
        generateAllStoreInvoiceXlsx: (
            query: {
                storeInvoiceIds: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto | void>({
                path: `/store-invoices/generate/all/xlsx`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name UpdateStoreInvoicesForAllStores
         * @request PATCH:/store-invoices/update/store-invoices
         * @response `200` `StoreInvoiceDto`
         * @response `404` `void`
         */
        updateStoreInvoicesForAllStores: (params: RequestParams = {}) =>
            this.request<StoreInvoiceDto, void>({
                path: `/store-invoices/update/store-invoices`,
                method: 'PATCH',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name RemoveTransactionFromStoreInvoice
         * @request DELETE:/store-invoices/{storeInvoiceId}/transaction/{transactionId}
         * @response `200` `StoreInvoiceDto`
         * @response `400` `ErrorsDto`
         * @response `404` `void`
         */
        removeTransactionFromStoreInvoice: (
            storeInvoiceId: string,
            transactionId: string,
            params: RequestParams = {},
        ) =>
            this.request<StoreInvoiceDto, ErrorsDto | void>({
                path: `/store-invoices/${storeInvoiceId}/transaction/${transactionId}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-invoices
         * @name GenerateStoreInvoicesForStores
         * @request POST:/store-invoices/generate
         * @response `200` `"launched" | "running"`
         * @response `404` `void`
         */
        generateStoreInvoicesForStores: (
            data: CreateStoreInvoicesDto,
            params: RequestParams = {},
        ) =>
            this.request<'launched' | 'running', void>({
                path: `/store-invoices/generate`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    auditLogs = {
        /**
         * No description
         *
         * @tags audit-logs
         * @name GetLogsForASpecificEntityId
         * @request GET:/audit-logs/{entityId}/logs
         * @response `200` `(GetAuditLogsResponseDto)[]`
         * @response `404` `void`
         */
        getLogsForASpecificEntityId: (entityId: string, params: RequestParams = {}) =>
            this.request<GetAuditLogsResponseDto[], void>({
                path: `/audit-logs/${entityId}/logs`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags audit-logs
 * @name GetAuditLogsWithDiffs
 * @request GET:/audit-logs/diffs
 * @response `200` `(PageDto & {
    data?: (GetAuditLogsResponseDto)[],

})` Successfully received getauditlogsresponsedto list
 */
        getAuditLogsWithDiffs: (
            query: {
                entityNames: string[];
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                event?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: GetAuditLogsResponseDto[];
                },
                any
            >({
                path: `/audit-logs/diffs`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    auth = {
        /**
         * No description
         *
         * @tags auth
         * @name LogIn
         * @request POST:/auth/log-in
         * @response `200` `LoginResponseDto`
         * @response `400` `ErrorsDto`
         */
        logIn: (data: LoginUserRequestDto, params: RequestParams = {}) =>
            this.request<LoginResponseDto, ErrorsDto>({
                path: `/auth/log-in`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name LogOut
         * @request POST:/auth/log-out
         * @response `400` `ErrorsDto`
         */
        logOut: (params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/auth/log-out`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name ResetPassword
         * @request POST:/auth/reset-password
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        resetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/auth/reset-password`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name ForgottenPassword
         * @request POST:/auth/request-new-password
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        forgottenPassword: (data: ForgottenPasswordDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/auth/request-new-password`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    claims = {
        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedExtracts
 * @request GET:/claims/extract
 * @response `200` `(PageDto & {
    data?: (ClaimExtractDto)[],

})` Successfully received claimextractdto list
 */
        getPaginatedExtracts: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimExtractDto[];
                },
                any
            >({
                path: `/claims/extract`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaims
 * @request GET:/claims
 * @response `200` `(PageDto & {
    data?: (ClaimDto)[],

})` Successfully received claimdto list
 */
        getPaginatedClaims: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                associated?: boolean;
                excludeClaimId?: string;
                status?: ClaimStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimDto[];
                },
                any
            >({
                path: `/claims`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetMyPaginatedClaims
 * @request GET:/claims/mine
 * @response `200` `(PageDto & {
    data?: (ClaimDto)[],

})` Successfully received claimdto list
 */
        getMyPaginatedClaims: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                associated?: boolean;
                excludeClaimId?: string;
                status?: ClaimStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimDto[];
                },
                any
            >({
                path: `/claims/mine`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedCustomerClaims
 * @request GET:/claims/customer-claims/{customerId}
 * @response `200` `(PageDto & {
    data?: (ClaimDto)[],

})` Successfully received claimdto list
 */
        getPaginatedCustomerClaims: (
            customerId: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                associated?: boolean;
                excludeClaimId?: string;
                status?: ClaimStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimDto[];
                },
                any
            >({
                path: `/claims/customer-claims/${customerId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name GetClaimsCountBySubscription
         * @request GET:/claims/by-subscription-count/{subscriptionId}
         * @response `200` `ClaimCountBySubscriptionDto`
         */
        getClaimsCountBySubscription: (subscriptionId: string, params: RequestParams = {}) =>
            this.request<ClaimCountBySubscriptionDto, any>({
                path: `/claims/by-subscription-count/${subscriptionId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaimsToPay
 * @request GET:/claims/to-pay
 * @response `200` `(PageDto & {
    data?: (ClaimDto)[],

})` Successfully received claimdto list
 */
        getPaginatedClaimsToPay: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimDto[];
                },
                any
            >({
                path: `/claims/to-pay`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name GetClaimsCount
         * @request GET:/claims/count
         * @response `200` `ClaimCountDto`
         */
        getClaimsCount: (params: RequestParams = {}) =>
            this.request<ClaimCountDto, any>({
                path: `/claims/count`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name GetClaimById
         * @request GET:/claims/{id}
         * @response `200` `ClaimDto`
         * @response `400` `ErrorsDto`
         */
        getClaimById: (id: string, params: RequestParams = {}) =>
            this.request<ClaimDto, ErrorsDto>({
                path: `/claims/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name DeleteClaim
         * @request DELETE:/claims/{id}
         * @response `204` `void`
         * @response `400` `ErrorsDto`
         */
        deleteClaim: (id: string, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/claims/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name UpdateClaimInformations
         * @request PATCH:/claims/{id}
         * @response `204` `void`
         * @response `400` `ErrorsDto`
         */
        updateClaimInformations: (id: string, data: UpdateClaimDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/claims/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name GetClaimFieldValues
         * @request GET:/claims/{id}/field-values
         * @response `200` `(FieldValueDto)[]`
         * @response `400` `ErrorsDto`
         */
        getClaimFieldValues: (id: string, params: RequestParams = {}) =>
            this.request<FieldValueDto[], ErrorsDto>({
                path: `/claims/${id}/field-values`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name UpdateClaimStatus
         * @request PATCH:/claims/{id}/status
         * @response `200` `ClaimDto`
         * @response `400` `ErrorsDto`
         */
        updateClaimStatus: (id: string, data: UpdateClaimStatusDto, params: RequestParams = {}) =>
            this.request<ClaimDto, ErrorsDto>({
                path: `/claims/${id}/status`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name UpdateClaimSubscriptions
         * @request PATCH:/claims/{id}/subscription
         * @response `200` `ClaimDto`
         * @response `400` `ErrorsDto`
         */
        updateClaimSubscriptions: (
            id: string,
            data: UpdateClaimSubscriptionsDto,
            params: RequestParams = {},
        ) =>
            this.request<ClaimDto, ErrorsDto>({
                path: `/claims/${id}/subscription`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name ClaimsControllerClaimFiles
         * @request GET:/claims/{id}/files
         * @response `200` `(ClaimFilesDto)[]`
         */
        claimsControllerClaimFiles: (id: string, params: RequestParams = {}) =>
            this.request<ClaimFilesDto[], any>({
                path: `/claims/${id}/files`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name AddClaimFiles
         * @request POST:/claims/{id}/files
         * @response `200` `void`
         * @response `400` `ErrorsDto`
         */
        addClaimFiles: (id: string, data: AddClaimFilesDto, params: RequestParams = {}) =>
            this.request<void, ErrorsDto>({
                path: `/claims/${id}/files`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name DeleteClaimFile
         * @request DELETE:/claims/files/{claimFileId}
         * @response `200` `void`
         */
        deleteClaimFile: (claimFileId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/claims/files/${claimFileId}`,
                method: 'DELETE',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaimFiles
 * @request GET:/claims/{id}/files/paginated
 * @response `200` `(PageDto & {
    data?: (ClaimFilesDto)[],

})` Successfully received claimfilesdto list
 */
        getPaginatedClaimFiles: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimFilesDto[];
                },
                any
            >({
                path: `/claims/${id}/files/paginated`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaimAmendments
 * @request GET:/claims/{id}/amendments/paginated
 * @response `200` `(PageDto & {
    data?: (ClaimAmendmentDto)[],

})` Successfully received claimamendmentdto list
 */
        getPaginatedClaimAmendments: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimAmendmentDto[];
                },
                any
            >({
                path: `/claims/${id}/amendments/paginated`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaimMessages
 * @request GET:/claims/{id}/messages/paginated
 * @response `200` `(PageDto & {
    data?: (ClaimMessageDto)[],

})` Successfully received claimmessagedto list
 */
        getPaginatedClaimMessages: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimMessageDto[];
                },
                any
            >({
                path: `/claims/${id}/messages/paginated`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedClaimNotes
 * @request GET:/claims/{id}/notes/paginated
 * @response `200` `(PageDto & {
    data?: (ClaimNoteDto)[],

})` Successfully received claimnotedto list
 */
        getPaginatedClaimNotes: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ClaimNoteDto[];
                },
                any
            >({
                path: `/claims/${id}/notes/paginated`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name UpdateClaimAssigned
         * @request PATCH:/claims/{id}/assigned
         * @response `200` `ClaimDto`
         * @response `400` `ErrorsDto`
         */
        updateClaimAssigned: (id: string, data: UpdateClaimAssigned, params: RequestParams = {}) =>
            this.request<ClaimDto, ErrorsDto>({
                path: `/claims/${id}/assigned`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags claims
 * @name GetPaginatedToPayCompensations
 * @request GET:/claims/compensations/list
 * @response `200` `(PageDto & {
    data?: (CompensationListItemDto)[],

})` Successfully received compensationlistitemdto list
 */
        getPaginatedToPayCompensations: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: CompensationListItemDto[];
                },
                any
            >({
                path: `/claims/compensations/list`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name GetCompensationsByClaimId
         * @request GET:/claims/{id}/compensations
         * @response `200` `(ClaimCompensationDto)[]`
         * @response `400` `ErrorsDto`
         */
        getCompensationsByClaimId: (id: string, params: RequestParams = {}) =>
            this.request<ClaimCompensationDto[], ErrorsDto>({
                path: `/claims/${id}/compensations`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name CreateCompensation
         * @request POST:/claims/compensations
         * @response `200` `void`
         */
        createCompensation: (data: CreateCompensationDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/claims/compensations`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name UpdateCompensationInformation
         * @request PATCH:/claims/compensations/{id}
         * @response `200` `ClaimCompensationDto`
         */
        updateCompensationInformation: (
            id: string,
            data: UpdateCompensationDto,
            params: RequestParams = {},
        ) =>
            this.request<ClaimCompensationDto, any>({
                path: `/claims/compensations/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags claims
         * @name DeleteCompensation
         * @request DELETE:/claims/compensations/{id}
         * @response `200` `void`
         */
        deleteCompensation: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/claims/compensations/${id}`,
                method: 'DELETE',
                ...params,
            }),
    };
    serviceAccounts = {
        /**
         * No description
         *
         * @tags service-accounts/claims
         * @name GetClaimByIdByServiceAccount
         * @request GET:/service-accounts/claims/{id}
         * @response `200` `ServiceAccountClaimDto`
         * @response `400` `ErrorsDto`
         */
        getClaimByIdByServiceAccount: (id: string, params: RequestParams = {}) =>
            this.request<ServiceAccountClaimDto, ErrorsDto>({
                path: `/service-accounts/claims/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts/claims
         * @name GetPaginatedClaimsForServiceAccount
         * @request GET:/service-accounts/claims
         * @response `200` `PageDto`
         * @response `400` `ErrorsDto`
         */
        getPaginatedClaimsForServiceAccount: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                status?: ClaimStatus[];
            },
            params: RequestParams = {},
        ) =>
            this.request<PageDto, ErrorsDto>({
                path: `/service-accounts/claims`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts
         * @name DeleteServiceAccount
         * @request DELETE:/service-accounts/{id}
         * @response `400` `ErrorsDto`
         */
        deleteServiceAccount: (id: string, params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/service-accounts/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts
         * @name GetServiceAccountStores
         * @request GET:/service-accounts/{id}/stores
         * @response `400` `ErrorsDto`
         */
        getServiceAccountStores: (id: string, params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/service-accounts/${id}/stores`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts
         * @name AuthServiceAccount
         * @request POST:/service-accounts/auth
         * @response `200` `LoginResponseDto`
         * @response `400` `ErrorsDto`
         */
        authServiceAccount: (data: ServiceAccountAuthDto, params: RequestParams = {}) =>
            this.request<LoginResponseDto, ErrorsDto>({
                path: `/service-accounts/auth`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts
         * @name LogOutServiceAccount
         * @request POST:/service-accounts/log-out
         * @response `400` `ErrorsDto`
         */
        logOutServiceAccount: (params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/service-accounts/log-out`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags service-accounts
         * @name GetAuthenticatedServiceAccount
         * @request GET:/service-accounts/me
         * @response `200` `ServiceAccountDto`
         */
        getAuthenticatedServiceAccount: (params: RequestParams = {}) =>
            this.request<ServiceAccountDto, any>({
                path: `/service-accounts/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    dataExports = {
        /**
         * No description
         *
         * @tags data-exports
         * @name CreateStoreSubscriptionExport
         * @request POST:/data-exports/stores/{storeId}/subscriptions
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        createStoreSubscriptionExport: (
            storeId: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
            },
            data: DataExportParamsDto,
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto>({
                path: `/data-exports/stores/${storeId}/subscriptions`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name CreateProviderSubscriptionExport
         * @request POST:/data-exports/provider/{providerId}/subscriptions
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        createProviderSubscriptionExport: (
            providerId: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
            },
            data: DataExportParamsDto,
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto>({
                path: `/data-exports/provider/${providerId}/subscriptions`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name CreateClaimsExport
         * @request POST:/data-exports/claims
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        createClaimsExport: (
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
            },
            data: DataExportParamsDto,
            params: RequestParams = {},
        ) =>
            this.request<string, ErrorsDto>({
                path: `/data-exports/claims`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name CreateConfigExport
         * @request POST:/data-exports/export-config
         * @response `200` `string`
         */
        createConfigExport: (data: ExportConfigurationQueryDto, params: RequestParams = {}) =>
            this.request<string, any>({
                path: `/data-exports/export-config`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name ImportConfig
         * @request POST:/data-exports/import-config
         * @response `201` `void`
         */
        importConfig: (data: ImportConfigDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/data-exports/import-config`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name ImportClaimFields
         * @request POST:/data-exports/import-claim-fields
         * @response `204` `void`
         */
        importClaimFields: (data: ImportClaimFieldsDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/data-exports/import-claim-fields`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-exports
         * @name CreateClaimsToPayXmlExport
         * @request POST:/data-exports/export-claims-to-pay
         * @response `200` `string`
         */
        createClaimsToPayXmlExport: (params: RequestParams = {}) =>
            this.request<string, any>({
                path: `/data-exports/export-claims-to-pay`,
                method: 'POST',
                format: 'json',
                ...params,
            }),
    };
    admins = {
        /**
         * No description
         *
         * @tags admins
         * @name CreateAdmin
         * @request POST:/admins
         * @response `400` `ErrorsDto`
         */
        createAdmin: (data: CreateAdminDto, params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/admins`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
 * No description
 *
 * @tags admins
 * @name GetAdmins
 * @request GET:/admins
 * @response `200` `(PageDto & {
    data?: (AdminDto)[],

})` Successfully received admindto list
 * @response `404` `void`
 */
        getAdmins: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @example "SuperAdmin" */
                roles?: AdminRole[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: AdminDto[];
                },
                void
            >({
                path: `/admins`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admins
         * @name RegisterAdmin
         * @request POST:/admins/register
         * @response `400` `ErrorsDto`
         */
        registerAdmin: (data: RegisterAdminDto, params: RequestParams = {}) =>
            this.request<any, ErrorsDto>({
                path: `/admins/register`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admins
         * @name GetAuthenticatedAdmin
         * @request GET:/admins/me
         * @response `200` `AdminDto`
         */
        getAuthenticatedAdmin: (params: RequestParams = {}) =>
            this.request<AdminDto, any>({
                path: `/admins/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admins
         * @name UpdateAdminRole
         * @request PATCH:/admins/{id}/role
         * @response `200` `void`
         */
        updateAdminRole: (id: string, data: UpdateAdminRoleDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admins/${id}/role`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
 * No description
 *
 * @tags admins
 * @name GetPaginatedClaimAdministrators
 * @request GET:/admins/claims
 * @response `200` `(PageDto & {
    data?: (AdminDto)[],

})` Successfully received admindto list
 * @response `404` `void`
 */
        getPaginatedClaimAdministrators: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
                /** @example "SuperAdmin" */
                roles?: AdminRole[];
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: AdminDto[];
                },
                void
            >({
                path: `/admins/claims`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admins
         * @name UpdateAdminInfos
         * @request PATCH:/admins/{id}
         * @response `200` `void`
         */
        updateAdminInfos: (id: string, data: UpdateAdminDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admins/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admins
         * @name DeleteAdmin
         * @request DELETE:/admins/{id}
         * @response `200` `void`
         * @response `404` `void`
         */
        deleteAdmin: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/admins/${id}`,
                method: 'DELETE',
                ...params,
            }),
    };
    invitations = {
        /**
         * No description
         *
         * @tags invitations
         * @name GetInvitation
         * @request GET:/invitations/{id}
         * @response `200` `InvitationDto`
         * @response `404` `void`
         */
        getInvitation: (id: string, params: RequestParams = {}) =>
            this.request<InvitationDto, void>({
                path: `/invitations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    customerRisk = {
        /**
         * No description
         *
         * @name CustomerRiskControllerUpdate
         * @request POST:/customer-risk/webhooks/update
         * @response `204` `void`
         */
        customerRiskControllerUpdate: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/customer-risk/webhooks/update`,
                method: 'POST',
                ...params,
            }),
    };
    products = {
        /**
 * No description
 *
 * @tags products
 * @name GetProducts
 * @request GET:/products
 * @response `200` `(PageDto & {
    data?: (ProductDto)[],

})` Successfully received productdto list
 */
        getProducts: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ProductDto[];
                },
                any
            >({
                path: `/products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name CreateProduct
         * @request POST:/products
         * @response `200` `ProductDto`
         * @response `400` `ErrorsDto`
         */
        createProduct: (data: CreateProductDto, params: RequestParams = {}) =>
            this.request<ProductDto, ErrorsDto>({
                path: `/products`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProduct
         * @request GET:/products/{id}
         * @response `200` `ProductDto`
         * @response `404` `void`
         */
        getProduct: (id: string, params: RequestParams = {}) =>
            this.request<ProductDto, void>({
                path: `/products/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name DeleteProduct
         * @request DELETE:/products/{id}
         * @response `200` `void`
         * @response `404` `void`
         */
        deleteProduct: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/products/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name DuplicateProduct
         * @request POST:/products/{id}/duplicate
         * @response `201` `ProductDto`
         * @response `400` `ErrorsDto`
         */
        duplicateProduct: (id: string, params: RequestParams = {}) =>
            this.request<ProductDto, ErrorsDto>({
                path: `/products/${id}/duplicate`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name UpdateProductInfo
         * @request PATCH:/products/{id}/info
         * @response `200` `ProductDto`
         * @response `404` `void`
         */
        updateProductInfo: (id: string, data: UpdateProductDto, params: RequestParams = {}) =>
            this.request<ProductDto, void>({
                path: `/products/${id}/info`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name UpdateProductPricing
         * @request PATCH:/products/{id}/pricing
         * @response `200` `ProductDto`
         * @response `404` `void`
         */
        updateProductPricing: (
            id: string,
            data: UpdateProductPricingDto,
            params: RequestParams = {},
        ) =>
            this.request<ProductDto, void>({
                path: `/products/${id}/pricing`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name UpdateProductCoverage
         * @request PATCH:/products/{id}/coverage
         * @response `200` `ProductDto`
         * @response `404` `void`
         */
        updateProductCoverage: (
            id: string,
            data: UpdateProductCoverageDto,
            params: RequestParams = {},
        ) =>
            this.request<ProductDto, void>({
                path: `/products/${id}/coverage`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name ComputeProductPrice
         * @request POST:/products/{id}/price
         * @response `200` `PriceProductDto`
         * @response `404` `void`
         */
        computeProductPrice: (
            id: string,
            data: GetSubscriptionPriceDto,
            params: RequestParams = {},
        ) =>
            this.request<PriceProductDto, void>({
                path: `/products/${id}/price`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags products
 * @name GetProductStores
 * @request GET:/products/{id}/stores
 * @response `200` `(PageDto & {
    data?: (StoreLiteDto)[],

})` Successfully received storelitedto list
 * @response `404` `void`
 */
        getProductStores: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: StoreLiteDto[];
                },
                void
            >({
                path: `/products/${id}/stores`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProductClaimFields
         * @request GET:/products/{id}/claim-fields
         * @response `200` `(ClaimFieldDto)[]`
         * @response `404` `void`
         */
        getProductClaimFields: (id: string, params: RequestParams = {}) =>
            this.request<ClaimFieldDto[], void>({
                path: `/products/${id}/claim-fields`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name UpdateProductClaimFields
         * @request PATCH:/products/{id}/claim-fields
         * @response `200` `(ClaimFieldDto)[]`
         * @response `404` `void`
         */
        updateProductClaimFields: (
            id: string,
            data: UpdateClaimFieldsDto,
            params: RequestParams = {},
        ) =>
            this.request<ClaimFieldDto[], void>({
                path: `/products/${id}/claim-fields`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProductSubscriptionDataFromFile
         * @request POST:/products/{id}/pdf-to-subscription-data
         * @response `200` `PdfToSubscriptionDataResponseDto`
         */
        getProductSubscriptionDataFromFile: (
            id: string,
            data: PdfToSubscriptionDataDto,
            params: RequestParams = {},
        ) =>
            this.request<PdfToSubscriptionDataResponseDto, any>({
                path: `/products/${id}/pdf-to-subscription-data`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProductClaimLink
         * @request GET:/products/{id}/claim-link
         * @response `200` `ProductClaimLinkDto`
         * @response `404` `void`
         */
        getProductClaimLink: (id: string, params: RequestParams = {}) =>
            this.request<ProductClaimLinkDto, void>({
                path: `/products/${id}/claim-link`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name SetProductClaimLink
         * @request PATCH:/products/{id}/claim-link
         * @response `200` `ProductClaimLinkDto`
         * @response `404` `void`
         */
        setProductClaimLink: (
            id: string,
            data: UpdateProductClaimLinkDto,
            params: RequestParams = {},
        ) =>
            this.request<ProductClaimLinkDto, void>({
                path: `/products/${id}/claim-link`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProductClaimPropertyMappings
         * @request GET:/products/{id}/claim-property-mappings
         * @response `200` `ResponseClaimPropertyMappingDto`
         * @response `404` `void`
         */
        getProductClaimPropertyMappings: (id: string, params: RequestParams = {}) =>
            this.request<ResponseClaimPropertyMappingDto, void>({
                path: `/products/${id}/claim-property-mappings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name SetProductClaimPropertyMappings
         * @request PATCH:/products/{id}/claim-property-mappings
         * @response `204` `void`
         * @response `404` `void`
         */
        setProductClaimPropertyMappings: (
            id: string,
            data: RequestUpdateClaimPropertyMappingsDto,
            params: RequestParams = {},
        ) =>
            this.request<void, void>({
                path: `/products/${id}/claim-property-mappings`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name ProductsControllerGetClaimLinkRedirect
         * @request GET:/products/{id}/claim-link-redirect
         * @response `404` `void`
         */
        productsControllerGetClaimLinkRedirect: (id: string, params: RequestParams = {}) =>
            this.request<any, void>({
                path: `/products/${id}/claim-link-redirect`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name ProductsControllerGetVoidLinkRedirect
         * @request GET:/products/{id}/void-link-redirect
         * @response `404` `void`
         */
        productsControllerGetVoidLinkRedirect: (id: string, params: RequestParams = {}) =>
            this.request<any, void>({
                path: `/products/${id}/void-link-redirect`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags products
         * @name GetProductFiles
         * @request GET:/products/{id}/files
         * @response `200` `(FileDto)[]`
         * @response `404` `void`
         */
        getProductFiles: (id: string, params: RequestParams = {}) =>
            this.request<FileDto[], void>({
                path: `/products/${id}/files`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    adminInvitations = {
        /**
         * No description
         *
         * @tags admin-invitations
         * @name GetAdminInvitation
         * @request GET:/admin-invitations/{id}
         * @response `200` `AdminInvitationDto`
         * @response `404` `void`
         */
        getAdminInvitation: (id: string, params: RequestParams = {}) =>
            this.request<AdminInvitationDto, void>({
                path: `/admin-invitations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin-invitations
         * @name CreateAdminInvitation
         * @request POST:/admin-invitations
         * @response `200` `InvitationDto`
         * @response `404` `void`
         */
        createAdminInvitation: (data: CreateAdminInvitationDto, params: RequestParams = {}) =>
            this.request<InvitationDto, void>({
                path: `/admin-invitations`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    providers = {
        /**
 * No description
 *
 * @tags providers
 * @name GetProviders
 * @request GET:/providers
 * @response `200` `(PageDto & {
    data?: (ProviderDto)[],

})` Successfully received providerdto list
 */
        getProviders: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ProviderDto[];
                },
                any
            >({
                path: `/providers`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name CreateProvider
         * @request POST:/providers
         * @response `200` `ProviderDto`
         * @response `400` `ErrorsDto`
         */
        createProvider: (data: CreateProviderDto, params: RequestParams = {}) =>
            this.request<ProviderDto, ErrorsDto>({
                path: `/providers`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name GetAllProviders
         * @request GET:/providers/all
         * @response `200` `(ProviderDto)[]`
         */
        getAllProviders: (params: RequestParams = {}) =>
            this.request<ProviderDto[], any>({
                path: `/providers/all`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name GetProvider
         * @request GET:/providers/{providerId}
         * @response `200` `ProviderDto`
         * @response `404` `void`
         */
        getProvider: (providerId: string, params: RequestParams = {}) =>
            this.request<ProviderDto, void>({
                path: `/providers/${providerId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name DeleteProvider
         * @request DELETE:/providers/{id}
         * @response `200` `void`
         * @response `404` `void`
         */
        deleteProvider: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/providers/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name UpdateProvider
         * @request PATCH:/providers/{id}
         * @response `200` `ProviderDto`
         * @response `404` `void`
         */
        updateProvider: (id: string, data: UpdateProviderDto, params: RequestParams = {}) =>
            this.request<ProviderDto, void>({
                path: `/providers/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags providers
 * @name GetUsers
 * @request GET:/providers/{id}/users
 * @response `200` `(PageDto & {
    data?: (ProviderAccountDto)[],

})` Successfully received provideraccountdto list
 * @response `404` `void`
 */
        getUsers: (
            id: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ProviderAccountDto[];
                },
                void
            >({
                path: `/providers/${id}/users`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags providers
         * @name CreateUser
         * @request POST:/providers/{id}/users
         * @response `200` `ProviderAccountDto`
         * @response `404` `void`
         */
        createUser: (id: string, data: CreateProviderAccountDto, params: RequestParams = {}) =>
            this.request<ProviderAccountDto, void>({
                path: `/providers/${id}/users`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags providers
 * @name GetSubscriptions
 * @request GET:/providers/{providerId}/subscriptions
 * @response `200` `(PageDto & {
    data?: (SubscriptionDto)[],

})` Successfully received subscriptiondto list
 */
        getSubscriptions: (
            providerId: string,
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: SubscriptionDto[];
                },
                any
            >({
                path: `/providers/${providerId}/subscriptions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    providerAccounts = {
        /**
         * No description
         *
         * @tags provider-accounts
         * @name GetAuthenticatedProviderAccount
         * @request GET:/provider-accounts/me
         * @response `200` `ProviderAccountDto`
         */
        getAuthenticatedProviderAccount: (params: RequestParams = {}) =>
            this.request<ProviderAccountDto, any>({
                path: `/provider-accounts/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    services = {
        /**
 * No description
 *
 * @tags services
 * @name GetServices
 * @request GET:/services
 * @response `200` `(PageDto & {
    data?: (ServiceDto)[],

})` Successfully received servicedto list
 */
        getServices: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: ServiceDto[];
                },
                any
            >({
                path: `/services`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags services
         * @name CreateService
         * @request POST:/services
         * @response `200` `ServiceDto`
         * @response `400` `ServiceDto`
         */
        createService: (data: CreateServiceDto, params: RequestParams = {}) =>
            this.request<ServiceDto, ServiceDto>({
                path: `/services`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags services
         * @name GetService
         * @request GET:/services/{id}
         * @response `200` `ServiceDto`
         * @response `404` `void`
         */
        getService: (id: string, params: RequestParams = {}) =>
            this.request<ServiceDto, void>({
                path: `/services/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags services
         * @name UpdateService
         * @request PATCH:/services/{id}
         * @response `200` `ServiceDto`
         * @response `404` `void`
         */
        updateService: (id: string, data: UpdateServiceDto, params: RequestParams = {}) =>
            this.request<ServiceDto, void>({
                path: `/services/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    statistics = {
        /**
         * No description
         *
         * @tags statistics
         * @name GetCountSubscriptionsByStore
         * @request GET:/statistics/stores/{id}/subscriptions
         * @response `200` `number`
         */
        getCountSubscriptionsByStore: (
            id: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<number, any>({
                path: `/statistics/stores/${id}/subscriptions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetCountCustomersByStore
         * @request GET:/statistics/stores/{id}/customers
         * @response `200` `number`
         */
        getCountCustomersByStore: (
            id: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<number, any>({
                path: `/statistics/stores/${id}/customers`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetPremiumByStore
         * @request GET:/statistics/stores/{id}/premium
         * @response `200` `number`
         */
        getPremiumByStore: (
            id: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<number, any>({
                path: `/statistics/stores/${id}/premium`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetStorePremiumByDay
         * @request GET:/statistics/stores/{id}/premium-by-day
         * @response `200` `(GetStorePremiumByDayDto)[]`
         */
        getStorePremiumByDay: (
            id: string,
            query?: {
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<GetStorePremiumByDayDto[], any>({
                path: `/statistics/stores/${id}/premium-by-day`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetStoreSalesByProducts
         * @request GET:/statistics/stores/{id}/products-sales
         * @response `200` `(GetStoreProductsSalesDto)[]`
         */
        getStoreSalesByProducts: (
            id: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<GetStoreProductsSalesDto[], any>({
                path: `/statistics/stores/${id}/products-sales`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetSubscriptionsCountByDateRange
         * @request GET:/statistics/stores/{id}/subscriptions-count-by-date-range
         * @response `200` `(GetSubscriptionsStatsByDateRange)[]`
         */
        getSubscriptionsCountByDateRange: (
            id: string,
            query: {
                /** @format date-time */
                from: string;
                /** @format date-time */
                to: string;
                selectedStoreIds?: string[];
            },
            params: RequestParams = {},
        ) =>
            this.request<GetSubscriptionsStatsByDateRange[], any>({
                path: `/statistics/stores/${id}/subscriptions-count-by-date-range`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetClaimAverageCost
         * @request GET:/statistics/claims/average-cost
         * @response `200` `number`
         */
        getClaimAverageCost: (
            query?: {
                month?: number;
                year?: number;
            },
            params: RequestParams = {},
        ) =>
            this.request<number, any>({
                path: `/statistics/claims/average-cost`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetTotalClaimByStatus
         * @request GET:/statistics/claims/total-by-status
         * @response `200` `(GetClaimsByStatus)[]`
         */
        getTotalClaimByStatus: (
            query?: {
                month?: number;
                year?: number;
            },
            params: RequestParams = {},
        ) =>
            this.request<GetClaimsByStatus[], any>({
                path: `/statistics/claims/total-by-status`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetClaimsCount
         * @request GET:/statistics/claims/count
         * @response `200` `number`
         */
        getClaimsCount: (
            query?: {
                month?: number;
                year?: number;
            },
            params: RequestParams = {},
        ) =>
            this.request<number, any>({
                path: `/statistics/claims/count`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags statistics
         * @name GetClaimsCountByDate
         * @request GET:/statistics/claims/count-by-date
         * @response `200` `(GetClaimsByDate)[]`
         */
        getClaimsCountByDate: (
            query?: {
                month?: number;
                year?: number;
            },
            params: RequestParams = {},
        ) =>
            this.request<GetClaimsByDate[], any>({
                path: `/statistics/claims/count-by-date`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    dataProviders = {
        /**
         * No description
         *
         * @tags data-providers
         * @name GetDataProvider
         * @request GET:/data-providers/{id}
         * @response `200` `DataProviderDto`
         * @response `404` `void`
         */
        getDataProvider: (id: string, params: RequestParams = {}) =>
            this.request<DataProviderDto, void>({
                path: `/data-providers/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name UpdateDataProvider
         * @request PATCH:/data-providers/{id}
         * @response `200` `DataProviderDto`
         * @response `404` `void`
         */
        updateDataProvider: (id: string, data: UpdateDataProviderDto, params: RequestParams = {}) =>
            this.request<DataProviderDto, void>({
                path: `/data-providers/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name DeleteDataProvider
         * @request DELETE:/data-providers/{id}
         * @response `204` `void`
         * @response `404` `void`
         */
        deleteDataProvider: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/data-providers/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name CreateDataProvider
         * @request POST:/data-providers
         * @response `200` `DataProviderDto`
         * @response `400` `ErrorsDto`
         */
        createDataProvider: (data: CreateDataProviderDto, params: RequestParams = {}) =>
            this.request<DataProviderDto, ErrorsDto>({
                path: `/data-providers`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * No description
 *
 * @tags data-providers
 * @name GetPaginatedDataProviders
 * @request GET:/data-providers/all/paginated
 * @response `200` `(PageDto & {
    data?: (DataProviderDto)[],

})` Successfully received dataproviderdto list
 */
        getPaginatedDataProviders: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: DataProviderDto[];
                },
                any
            >({
                path: `/data-providers/all/paginated`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name GetAllDataProviders
         * @request GET:/data-providers/all/unpagined
         * @response `200` `(DataProviderDto)[]`
         */
        getAllDataProviders: (params: RequestParams = {}) =>
            this.request<DataProviderDto[], any>({
                path: `/data-providers/all/unpagined`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name CreateDataProviderImportLog
         * @request POST:/data-providers/import-log/create
         * @response `201` `ResponseCreateDataProviderImportLogDto`
         * @response `400` `ErrorsDto`
         */
        createDataProviderImportLog: (
            data: RequestCreateDataProviderImportLogDto,
            params: RequestParams = {},
        ) =>
            this.request<ResponseCreateDataProviderImportLogDto, ErrorsDto>({
                path: `/data-providers/import-log/create`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name AddErrorToDataProviderImportLog
         * @request PUT:/data-providers/import-log/{id}/add-error
         * @response `204` `void`
         * @response `404` `void`
         */
        addErrorToDataProviderImportLog: (
            id: string,
            data: RequestAddErrorToImportLogDto,
            params: RequestParams = {},
        ) =>
            this.request<void, void>({
                path: `/data-providers/import-log/${id}/add-error`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags data-providers
         * @name AddFileToDataProviderImportLog
         * @request PUT:/data-providers/import-log/{id}/add-file
         * @response `204` `void`
         * @response `404` `void`
         */
        addFileToDataProviderImportLog: (
            id: string,
            data: RequestAddFileImportLogDto,
            params: RequestParams = {},
        ) =>
            this.request<void, void>({
                path: `/data-providers/import-log/${id}/add-file`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    documentTemplates = {
        /**
 * No description
 *
 * @tags document-templates
 * @name GetDocumentTemplates
 * @request GET:/document-templates
 * @response `200` `(PageDto & {
    data?: (DocumentTemplateDto)[],

})` Successfully received documenttemplatedto list
 */
        getDocumentTemplates: (
            query?: {
                /** @default "ASC" */
                order?: 'ASC' | 'DESC';
                /**
                 * @min 1
                 * @default 1
                 */
                page?: number;
                /**
                 * @min 1
                 * @max 100
                 * @default 10
                 */
                limit?: number;
                q?: string;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                PageDto & {
                    data?: DocumentTemplateDto[];
                },
                any
            >({
                path: `/document-templates`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags document-templates
         * @name CreateDocumentTemplate
         * @request POST:/document-templates
         * @response `200` `DocumentTemplateDto`
         */
        createDocumentTemplate: (data: CreateDocumentTemplateDto, params: RequestParams = {}) =>
            this.request<DocumentTemplateDto, any>({
                path: `/document-templates`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags document-templates
         * @name GetDocumentTemplate
         * @request GET:/document-templates/{id}
         * @response `200` `DocumentTemplateDto`
         * @response `404` `void`
         */
        getDocumentTemplate: (id: string, params: RequestParams = {}) =>
            this.request<DocumentTemplateDto, void>({
                path: `/document-templates/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags document-templates
         * @name UpdateDocumentTemplate
         * @request PATCH:/document-templates/{id}
         * @response `200` `DocumentTemplateDto`
         * @response `404` `void`
         */
        updateDocumentTemplate: (
            id: string,
            data: UpdateDocumentTemplateDto,
            params: RequestParams = {},
        ) =>
            this.request<DocumentTemplateDto, void>({
                path: `/document-templates/${id}`,
                method: 'PATCH',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    templateImport = {
        /**
         * No description
         *
         * @tags template-import
         * @name GetContractImportTemplate
         * @request GET:/template-import/contract/{id}/template/{action}
         * @response `200` `string`
         * @response `400` `ErrorsDto`
         */
        getContractImportTemplate: (id: string, action: string, params: RequestParams = {}) =>
            this.request<string, ErrorsDto>({
                path: `/template-import/contract/${id}/template/${action}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags template-import
         * @name ImportSubscriptionByCsv
         * @request POST:/template-import/contract/{id}/import/create/csv
         * @response `200` `ImportCsvResponseDto`
         * @response `400` `ErrorHeaderDto`
         */
        importSubscriptionByCsv: (id: string, data: CSVDocumentDto, params: RequestParams = {}) =>
            this.request<ImportCsvResponseDto, ErrorHeaderDto>({
                path: `/template-import/contract/${id}/import/create/csv`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags template-import
         * @name ImportSubscriptionByJson
         * @request POST:/template-import/contract/{id}/import/create/json
         * @response `200` `ImportCsvResponseDto`
         * @response `400` `ErrorHeaderDto`
         */
        importSubscriptionByJson: (id: string, data: string[], params: RequestParams = {}) =>
            this.request<ImportCsvResponseDto, ErrorHeaderDto>({
                path: `/template-import/contract/${id}/import/create/json`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags template-import
         * @name GetImportsHistoryByStore
         * @request GET:/template-import/store/{id}/import/history
         * @response `200` `(ImportJobDataByStoreDto)[]`
         */
        getImportsHistoryByStore: (id: string, params: RequestParams = {}) =>
            this.request<ImportJobDataByStoreDto[], any>({
                path: `/template-import/store/${id}/import/history`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
    api = {
        /**
         * No description
         *
         * @tags ticketac
         * @name SubscribeTicketac
         * @request POST:/api/v1/application/{storeReference}/company/contracts/create
         * @response `400` `ErrorsDto`
         */
        subscribeTicketac: (
            storeReference: string,
            data: CreateTicketacSubscriptionsDto,
            params: RequestParams = {},
        ) =>
            this.request<any, ErrorsDto>({
                path: `/api/v1/application/${storeReference}/company/contracts/create`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    tags = {
        /**
         * No description
         *
         * @tags tags
         * @name GetProductTagsList
         * @summary Get product tags list
         * @request GET:/tags/product-list
         * @response `200` `(TagDto)[]`
         */
        getProductTagsList: (params: RequestParams = {}) =>
            this.request<TagDto[], any>({
                path: `/tags/product-list`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags tags
         * @name GetAllTagsFromStore
         * @request GET:/tags/store/{id}/products/tags
         * @response `200` `(TagDto)[]`
         */
        getAllTagsFromStore: (id: string, params: RequestParams = {}) =>
            this.request<TagDto[], any>({
                path: `/tags/store/${id}/products/tags`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
}
